import React, { useState } from "react";

const FrmTeacher = ({ fields, setFields }) => {
    const [show, setShow] = useState(false);

    const showPassword = () => {
        setShow(prevState => !prevState)
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <div className="mb-3">
                <label htmlFor="nameTeacher" className="form-label">
                    Nombres
                </label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresar nombres."
                    name="nameTeacher"
                    value={ fields.nameTeacher }
                    onChange={ handleInputChange }
                />
            </div>

            <div className="mb-3">
                <label htmlFor="lastNameTeacher" className="form-label">
                    Apellidos
                </label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresar apellidos."
                    name="lastNameTeacher"
                    value={ fields.lastNameTeacher }
                    onChange={ handleInputChange }
                />
            </div>

            <div className="mb-3">
                <label htmlFor="addressTeacher" className="form-label">
                    Dirección de Domicilio
                </label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresar dirección."
                    name="addressTeacher"
                    value={ fields.addressTeacher }
                    onChange={ handleInputChange }
                />
            </div>
            <div className="mb-3">
                <label htmlFor="phoneTeacher" className="form-label">
                    Celular
                </label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresar dirección."
                    name="phoneTeacher"
                    value={ fields.phoneTeacher }
                    onChange={ handleInputChange }

                />
            </div>
            <div className="mb-3">
                <label htmlFor="emailTeacher" className="form-label">
                    Correo Electrónico
                </label>
                <input
                    type="email"
                    className="form-control"
                    placeholder="name@example.com"
                    name="emailTeacher"
                    value={ fields.emailTeacher }
                    onChange={ handleInputChange }

                />
            </div>

            <div className="mb-3">
                <label htmlFor="passwordTeacher" className="form-label">
                    Contraseña
                </label>
                <div className="input-group">
                    <input
                        type={show ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Contraseña..."
                        name="passwordTeacher"
                        value={ fields.passwordTeacher }
                        onChange={ handleInputChange }

                    />
                    <button className="btn btn-secondary" onClick={showPassword}>
                        {show ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                    </button>
                </div>
            </div>
        </>
    );
};

export default FrmTeacher;
