import React, { useState, useEffect } from "react";
import '../../assets/css/games.css'
import '../../assets/css/menuLaberintos.css';

import { useLocation } from "react-router-dom";
import Laberintos from '../../components/games/juegos_concentracion/laberintos'
import MenuLaberintos from "../../components/games/juegos_concentracion/menu_laberintos";
import { Link } from 'react-router-dom'
import { assignTeacherIntentos } from "../../service/assignTeacher";
import MatchingGame from "../../components/games/juegos_concentracion/MatchingGame";
import NumberSequencingGame from "../../components/games/juegos_concentracion/NumberSequencingGame";
import NumberOperation from "../../components/games/juegos_concentracion/numberOperation";
import NumberProduct from "../../components/games/juegos_concentracion/numberProduct";
import AlphabetSoup from "../../components/games/juegos_concentracion/alphabetSoup";
import BuscaLaDiferencia from "../../components/games/juegos_concentracion/differences";
import Puzzle from "../../components/games/juegos_concentracion/puzzle";

export const ScreenGame = () => {
    const location = useLocation();
    const { title, nivel, id } = location.state;
    const [selectedGame, setSelectedGame] = useState("Laberintos");
    const [isPlaying, setIsPlaying] = useState(false);

    const defaultFields = {
        id: id
    };
    const [fields, setFields] = useState(defaultFields);


    const handlePlay = async () => {
        setIsPlaying(true);
        // const response = await assignTeacherIntentos(defaultFields);
        // console.log(response);

        console.log(isPlaying);
    };

    const handleExit = () => {
        setIsPlaying(false);
        window.location = "/alumno";
    };

    const handleGameSelection = (event) => {
        setSelectedGame(event.target.value);
        renderGame(title);
    };

    const renderGame = (title) => {
        switch (title) {
            case 'Juego de secuencia numérica':
                return <NumberSequencingGame nivel={nivel} id={id} />;
            case 'Memorias':
                return <MatchingGame nivel={nivel} id={id} />;
            case 'Laberintos':
                return <Laberintos nivel={nivel} id={id} />;
            case 'Operación con números naturales':
                return <NumberOperation nivel={nivel} id={id} />
            case 'Operación de la multiplicación y división':
                return <NumberProduct nivel={nivel} id={id} />
            case 'Sopa de letras':
                return <AlphabetSoup nivel={nivel} id={id} />
            case 'Busca las diferencias':
                return <BuscaLaDiferencia nivel={nivel} id={id} />
            case 'Juegos de Rompecabezas':
                return <Puzzle nivel={nivel} id={id} />
            default:
                return <MenuLaberintos onPlay={handlePlay} onExit={handleExit} />;
        }
    };

    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        // Función que maneja el cambio del estado de pantalla completa
        const handleFullScreenChange = () => {
            if (
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement
            ) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }
        };

        // Agregar el listener al cambio de pantalla completa
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        // Limpiar el listener al desmontar el componente
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, []);

    const handleFullScreenToggle = () => {
        const element = document.querySelector('.container-game');

        if (!isFullScreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) { // Firefox
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { // IE/Edge
                element.msRequestFullscreen();
            }
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
            setIsFullScreen(false);
        }
    };

    return (
        <>
            <div>
                <div>
                    <Link to="/alumno">
                        <button className="main__btn main__btn__outline__primary">Regresar</button>
                    </Link>
                    <div>
                        <br></br>
                        <span className="main__titulo__"><h4>{title} Nivel {nivel}</h4></span>
                    </div>
                    {/* <h3>Selecciona un juego</h3>
                        <select onChange={handleGameSelection} value={selectedGame}>
                            <option value="Laberintos">Selecciona un juego</option>
                            <option value="NumberSequencingGame">Secuenciación Numérica</option>
                            <option value="MatchingGame">Memoria de imagenes</option>
                            <option value="Laberintos">Laberintos</option>
                        </select> */}
                    <div className='container-game'>
                        {isPlaying ? (
                            <>
                                 <button onClick={handleFullScreenToggle} className='fullscreen-toggle'>
                                    <i className={`fas ${isFullScreen ? 'fa-compress' : 'fa-expand'}`}></i>
                                </button>
                                {renderGame(title)}
                            </>
                        ) : (
                            <MenuLaberintos onPlay={handlePlay} onExit={handleExit} />
                        )}
                    </div>
                </div>

            </div>
        </>
    );
};
