import React, { useState, useEffect, useRef } from 'react';
import './css/NumberSequencingGame.css';
import { assignTeacherDuracion } from '../../../service/assignTeacher';
import { InsertProgresoEstudiante, updateProgreso } from '../../../service/progresoEstudiante';

// CONFETI
import Confetti from 'react-confetti';

import { useNavigate } from 'react-router-dom';

const levelConfigs = {
    1: { range: 10, shuffleTimes: 5 },
    2: { range: 15, shuffleTimes: 10 },
    3: { range: 20, shuffleTimes: 20 },
};

// const initialNumbers = [3, 1, 4, 2, 5, 9, 6, 7, 8, 10];

function NumberSequencingGame({ nivel, id }) {
    const [numbers, setNumbers] = useState([]);
    const [timeTaken, setTimeTaken] = useState(null);
    const [startTime, setStartTime] = useState(null);

    const [insertar, setInsertar] = useState(true);
    const [progreso, setProgreso] = useState([]);

    const hasInsertedProgress = useRef(false);

    
    // CONFETI
    const [showConfetti, setShowConfetti] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const { range, shuffleTimes } = levelConfigs[nivel] || levelConfigs[1];
        const initialNumbers = Array.from({ length: range }, (_, i) => i + 1);
        setNumbers(shuffle(initialNumbers, shuffleTimes));
        // setNumbers(shuffle(initialNumbers));
    }, []);

    useEffect(() => {
        if (insertar && !hasInsertedProgress.current) {
            hasInsertedProgress.current = true;
            (async () => {
                const defaultFields = {
                    idAssignTeacher: id,
                    duracion: '0',
                    completados: 0,
                };
                const response = await InsertProgresoEstudiante(defaultFields);
                setProgreso(response.progreso_estudiante);
                console.log(response.progreso_estudiante);
            })();
        }
    }, [insertar, startTime, id]);

    function shuffle(array, times) {
        for (let i = 0; i < times; i++) {
            array.sort(() => Math.random() - 0.5);
        }
        return array;
    }

    async function handleNumberClick(index) {
        if (!startTime) setStartTime(Date.now());
        const newNumbers = [...numbers];
        [newNumbers[index], newNumbers[index + 1]] = [newNumbers[index + 1], newNumbers[index]];
        setNumbers(newNumbers);
        if (newNumbers.every((num, i, arr) => i === 0 || arr[i - 1] <= num)) {
            const endTime = Date.now();
            setTimeTaken((endTime - startTime) / 1000);

            // const defaultFields = {
            //     idAssignTeacher: id,
            //     duracion: formatTime((endTime - startTime) / 1000),
            //     completados: 1,
            // };

            // const response = await InsertProgresoEstudiante(defaultFields);

            setShowConfetti(true);
            setShowMessage(true);
            const audio = new Audio('/sounds/applause-small-audience-97257.mp3'); 
            audio.play();

            const defaultFields = {
                id: progreso.id,
                duracion: formatTime((endTime - startTime) / 1000),
                completados: 1,
            };

            updateProgreso(defaultFields).then(response => {
                console.log(response);
            });

            setTimeout(() => {
                setShowConfetti(false);
                setShowMessage(false);
                // window.location = "/alumno";
                navigate('/alumno');
            }, 6000);
        }
    }

    function formatTime(tiempo) {
        const hours = Math.floor(tiempo / 3600);
        const minutes = Math.floor((tiempo % 3600) / 60);
        const seconds = tiempo % 60;

        const pad = (num) => (num < 10 ? `0${num}` : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };

    return (
        <>

            <div className="number-sequencing-game">
                <h2>Secuenciación Numérica</h2>
                <div className="numbers-grid">
                    {numbers.map((number, index) => (
                        <div
                            key={index}
                            className="number-item"
                            onClick={() => index < numbers.length - 1 && handleNumberClick(index)}
                        >
                            {number}
                        </div>
                    ))}
                </div>
                {/* {timeTaken && <p>Tiempo tomado: {timeTaken} segundos</p>} */}
            </div>

            {showConfetti && <Confetti />}
            {showMessage && (
                <div style={messageStyle}>
                    ¡Ganaste!
                </div>
            )}
        </>

    );
}

const messageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    fontSize: '2rem',
    zIndex: 1000,
    textAlign: 'center',
};

export default NumberSequencingGame;
