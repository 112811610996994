import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getStudentById } from '../../../service/students';

const PerfilAlumno = () => {

    const [student, setStudent] = useState([]);
    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const id = localStorage.getItem("alumnoID");
        const response = await getStudentById(id);
        console.log(response);
        if (response.student) {
            setStudent(response.student);
        }
    };


    return (
        <>
            <div className='main__container__'>
                <div className='container__perfil'>

                    <div className='container__alumno'>
                        <span className='main__titulo__'>Datos Personales</span>

                        <div className='main__container__datos'>
                            <span className='main__subtitulo__'>Nombres: </span>
                            <span className='main__datos__'>{student.name}  </span>
                        </div>

                        <div className='main__container__datos'>
                            <span className='main__subtitulo__'>Apellidos: </span>
                            <span className='main__datos__'>{student.lastName}  </span>
                        </div>

                        <div className='main__container__datos'>
                            <span className='main__subtitulo__'>Dirección de Domicilio: </span>
                            <span className='main__datos__'>{student.address}  </span>
                        </div>

                        <div className='main__container__datos'>
                            <span className='main__subtitulo__'>Celular: </span>
                            <span className='main__datos__'>{student.phone}  </span>
                        </div>

                        <div className='main__container__datos'>
                            <span className='main__subtitulo__'>Correo: </span>
                            <span className='main__datos__'>{student.email}  </span>
                        </div>

                        <div className='btn__regresar btn_primero'>
                            <Link to="/alumno">
                                <button className="main__btn main__btn__outline__primary">Regresar</button>
                            </Link>

                        </div>


                    </div>

                    <div className='divisor__perfil'>

                    </div>

                    <div className='container__alumno'>
                        <span className='main__titulo__'>Datos Apoderado</span>

                        <div className='main__container__datos'>
                            <span className='main__subtitulo__'>Nombres: </span>
                            <span className='main__datos__'>{student.nameRepresentante}  </span>
                        </div>

                        <div className='main__container__datos'>
                            <span className='main__subtitulo__'>Celular: </span>
                            <span className='main__datos__'>{student.phoneRepresentante}  </span>
                        </div>
                    </div>

                    <div className='btn__regresar btn_segundo'>
                    <Link to="/alumno">
                        <button className="main__btn main__btn__outline__primary">Regresar</button>
                    </Link>

                </div>

                </div>

                
                {/* <div className='main__container__right'>
                <span className='main__titulo__'>Profesores</span>
                
            </div> */}

            </div>
        </>
    )
}

export default PerfilAlumno