import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

// APIS
import { getAssignByIdTeacher } from "../../../service/assign";

// MODAL
import MyModal from "../../../components/modal";

// FORMULARIO
import FrmCurso from "./frmCurso";
import FrmArchivo from "./frmArchivo";
import { getAssignTeacherByIdAssign } from "../../../service/assignTeacher";

// IMAGEN PDF
import img_pdf from "../../../assets/img/img_pdf.jpeg"

const Cursos = () => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        id: 0,
        idAssign: 0,
        idStudent: 0,
        note: ""
    };

    const defaultArchivos = {
        archivos: ""
    }

    // CONSTANTES
    const [cursos, setCursos] = useState();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [fields, setFields] = useState(defaultFields);
    const [archivos, setArchivos] = useState(defaultArchivos);
    const [id, setAlumnoID] = useState();

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // MOSTRAR | OCULTAR MODAL
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFields(defaultFields);
    };

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => {
        setShow2(true);
    };

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getAssignByIdTeacher(localStorage.getItem("profesorID"));
        if (response.assignments) {
            setCursos(response.assignments);
        }
        console.log(response.assignments);
    };

    // VER ALUMNOS
    const verAlumnos = async (id) => {
        setAlumnoID(id);
        handleShow();
    }

    // SUBIR ARCHIVOS
    const subirArchivos = async (id) => {
        setAlumnoID(id);
        handleShow2();
    }

    // GENERAR REPORTE
    const generarReporte = async (id, nameFile = "") => {
        const response = await getAssignTeacherByIdAssign(id);
        const data = response.assignments;
        const doc = new jsPDF();

        console.log(data);

        // Cargar la imagen (puede ser una URL o una cadena base64)
        const img = new Image();
        img.src = img_pdf;

        img.onload = () => {
            // Calcular el ancho y la posición X de la imagen para centrarla
            const imgWidth = 50; // Ajusta el ancho de la imagen
            const pageWidth = doc.internal.pageSize.width;
            const x = (pageWidth - imgWidth) / 2;

            // Agregar la imagen al PDF
            doc.addImage(img, 'JPEG', x, 30, imgWidth, 50); // Ajusta la altura según sea necesario

            // Calcular la posición Y para la tabla
            const startY = 90;

            // Título del PDF
            const title = "Escuela de Educación básica particular";
            const title2 = "centinela iberoamericana cristiana";

            const pageMitad = pageWidth / 2;

            doc.text(title, pageMitad, 10, { align: 'center' });
            doc.text(title2, pageMitad, 20, { align: 'center' });

            // Definir las columnas y las filas de la tabla
            const columns = [
                "Estudiante",
                "Juego",
                "Grado",
                "Sección",
                "Año Lectivo",
                "Intentos",
                // "Duración", 
                // "Completados", 
                "Nota",
                "Observación"
            ];

            const rows = data.map(data => [
                `${data.student.name} ${data.student.lastName}`,
                `${data.assign.course.title} ${data.assign.course.nivel}`,
                data.degreeAssign,
                data.sectionAssign,
                data.anioLectivo,
                data.intentos,
                // data.completados,
                data.note,
                data.comentario
            ]);

            // Generar la tabla
            doc.autoTable({
                head: [columns],
                body: rows,
                startY: startY, // Posiciona la tabla justo debajo de la imagen
            });

            // Guardar el PDF con un nombre
            if (nameFile != "") {
                doc.save(`${nameFile}_.pdf`);

            } else {
                doc.save(`reporte_asignacion_${id}.pdf`);

            }
        };
    }

    const generarReporte2 = (doc, data) => {
        return new Promise((resolve) => {
            // Cargar la imagen (puede ser una URL o una cadena base64)
            const img = new Image();
            img.src = img_pdf;

            img.onload = () => {
                // Calcular el ancho y la posición X de la imagen para centrarla
                const imgWidth = 50; // Ajusta el ancho de la imagen
                const pageWidth = doc.internal.pageSize.width;
                const x = (pageWidth - imgWidth) / 2;

                // Agregar la imagen al PDF
                doc.addImage(img, 'JPEG', x, 30, imgWidth, 50); // Ajusta la altura según sea necesario

                // Calcular la posición Y para la tabla
                const startY = 90;

                // Título del PDF
                const title = "Escuela de Educación básica particular";
                const title2 = "centinela iberoamericana cristiana";

                const pageMitad = pageWidth / 2;

                doc.text(title, pageMitad, 10, { align: 'center' });
                doc.text(title2, pageMitad, 20, { align: 'center' });

                // Definir las columnas y las filas de la tabla
                const columns = [
                    "Estudiante",
                    "Juego",
                    "Grado",
                    "Sección",
                    "Año Lectivo",
                    "Intentos",
                    // "Duración", 
                    // "Completados", 
                    "Nota",
                    "Observación"
                ];

                const rows = data.map(data => [
                    `${data.student.name} ${data.student.lastName}`,
                    `${data.assign.course.title} ${data.assign.course.nivel}`,
                    data.degreeAssign,
                    data.sectionAssign,
                    data.anioLectivo,
                    data.intentos,
                    // data.completados,
                    data.note,
                    data.comentario
                ]);

                // Generar la tabla
                doc.autoTable({
                    head: [columns],
                    body: rows,
                    startY: startY, // Posiciona la tabla justo debajo de la imagen
                });

                resolve(); // Resuelve la promesa para indicar que se completó la adición de datos
            };
        });
    };

    // GENERAR REPORTE
    const generarReporteGeneral = async () => {
        const doc = new jsPDF(); // Crea un nuevo documento PDF

        try {
            // Suponiendo que 'cursos' es una lista de IDs o objetos de curso
            const data = cursos; // Obtén los cursos

            for (const curso of data) {
                const idCurso = curso.id;
                const nameFile = `${curso.course.category}`;

                // Obtén los datos de asignación para cada curso
                const response = await getAssignTeacherByIdAssign(idCurso);
                const assignmentsData = response.assignments;

                // Agrega el contenido del reporte al documento
                await generarReporte2(doc, assignmentsData);

                // Agrega una nueva página para cada curso
                doc.addPage();
            }

            // Guardar el PDF con un nombre
            doc.save('reporte_general.pdf');
        } catch (error) {
            console.error('Error al generar el reporte general:', error);
        }
    };


    return (
        <>
            <h1 className="mb-4">Recursos</h1>
            <button className="btn btn-danger px-3 mb-3" onClick={() => generarReporteGeneral()}>
                <i className="fa-solid fa-file"></i> Reporte General
            </button>
            {cursos && cursos.length > 0 ? (
                cursos.map((curso) => (
                    <div className="card mb-3" key={curso.id}>
                        <div className="card-header">
                            {curso.course.category}
                        </div>
                        <div className="card-body">
                            <h5 className="card-title"><b>{curso.course.title} Nivel: {curso.course.nivel}</b></h5>
                            <p className="card-text">{curso.course.des}</p>
                            <button className="btn btn-success px-3 me-3" onClick={() => verAlumnos(curso.id)}>
                                <i className="fa-solid fa-eye"></i> Ver Alumnos
                            </button>
                            <button className="btn btn-primary px-3 me-3" onClick={() => subirArchivos(curso.id)}>
                                <i className="fa-solid fa-upload"></i> Subir Archivo
                            </button>
                            <button className="btn btn-danger px-3" onClick={() => generarReporte(curso.id)}>
                                <i className="fa-solid fa-file"></i> Reporte
                            </button>
                        </div>
                    </div>
                ))
            ) : (
                <h5 className="text-center">
                    No hay datos disponibles.
                </h5>
            )}

            <MyModal
                show={show}
                handleClose={handleClose}
                title={localStorage.getItem("profesorName")}
                content={<FrmCurso fields={fields} setFields={setFields} idAlumno={id} />}
                size="lg"
                ocultarBtn={true}
            />

            <MyModal
                show={show2}
                handleClose={handleClose2}
                title={"Subir Archivos"}
                content={<FrmArchivo archivos={archivos} setArchivos={setArchivos} id={id} setShow2={setShow2} />}
                size="lg"
                ocultarBtn={true}

            />

        </>
    );
};

export default Cursos;
