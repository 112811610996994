import React from 'react';

const MenuLaberintos = ({ onPlay, onExit }) => {
  return (
    <div className="menu-container">
      <h1 className="menu-title">Nuevo Juego</h1>
      <div className="button-container">
        <button className="menu-button" onClick={onPlay}>
          Iniciar Juego
        </button>
        <button className="menu-button" onClick={onExit}>
          Salir
        </button>
      </div>
    </div>
  );
}

export default MenuLaberintos;
