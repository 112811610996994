import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from '../views/login';
import Navbar from '../components/navbar';

/* Vistas */
import ShowTeachers from '../views/teacher';
import ShowStudents from '../views/students';
import ShowCourses from '../views/courses';
import ShowAssignCourses from '../views/assignCourses';
import ShowAssignTeacher from '../views/assignTeacher';
import Register from '../views/register';

// Profesor
// import NavbarProfesor from '../components/navbarProfesor';
import LoginProfesor from '../views/profesor/login/index';
import Cursos from '../views/profesor/cursos/index';
import ElegirRecursos from '../views/profesor/elegirRecursos';
import ElegirAlumnos from '../views/profesor/elegirAlumnos';

// Alumno
import LoginAlumno from '../views/alumno/login/index';
import DashboardAlumno from '../views/alumno/dashboard';
import NavbarAlumno from '../components/navbarAlumno';
import CursosAlumno from '../views/alumno/cursos';
import Disenio from '../components/navbarProfesor/disenio';
import { ScreenGame } from '../views/games';
import NavbarNuevo from '../components/navbarAlumno/navbarNuevo';
import PerfilAlumno from '../views/alumno/perfil';
import ArchivosAlumno from '../views/alumno/archivos';


export const Router = () => {

  const Menu = (content) => {
    return (
      <>
        <Navbar content={content} />
      </>
    )
  }

  const MenuProfesor = (content) => {
    return (
      <>
        <Disenio content={content} />
      </>
    )
  }

  const MenuAlumno = (content) => {
    return (
      <>
        <NavbarAlumno content={content} />
      </>
    )
  }

  const MenuAlumnoNuevo = (content, game) => {
    return (
      <>
        <NavbarNuevo content={content} banner={game} />
      </>
    )
  }

  const token = localStorage.getItem('token');
  const tokenProfesor = localStorage.getItem('tokenProfesor');
  const tokenAlumno = localStorage.getItem('tokenAlumno');

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={ token ? Menu(<ShowTeachers />) :  <Login /> } />
        {/* <Route path="/" element={ <Login /> } /> */}

        <Route path="/register" element={ token ? Menu(<ShowTeachers />) : <Register /> } />
        {/* <Route path="/register" element={ <Login /> } /> */}

        <Route  path="/profesores" element={ token ?  Menu(<ShowTeachers />) : <Navigate to={"/"} /> } />
        {/* <Route  path="/profesores" element={ Menu(<ShowTeachers />) } /> */}

        <Route  path="/alumnos" element={ token ? Menu(<ShowStudents />) : <Navigate to={"/"} /> } />
        {/* <Route  path="/alumnos" element={ Menu(<ShowStudents />) } /> */}

        <Route  path="/cursos" element={ token ? Menu(<ShowCourses />) : <Navigate to={"/"} /> } />
        {/* <Route  path="/cursos" element={ Menu(<ShowCourses />) } /> */}

        <Route  path="/asignamiento" element={ token ? Menu(<ShowAssignCourses />) : <Navigate to={"/"} /> } />
        {/* <Route  path="/asignamiento" element={ Menu(<ShowAssignCourses />) } /> */}

        <Route  path="/asignamientoProfesor" element={ token ? Menu(<ShowAssignTeacher />) : <Navigate to={"/"} /> } />
        {/* <Route  path="/asignamientoProfesor" element={ Menu(<ShowAssignTeacher />) } /> */}

        {/* PROFESOR */}
        <Route path="/profesor/login" element={ tokenProfesor ? MenuProfesor(<Cursos />) : <LoginProfesor /> } />
        {/* <Route path="/profesor/login" element={ <LoginProfesor /> } /> */}

        <Route path="/profesor/recursos" element={ tokenProfesor ? MenuProfesor(<Cursos />) : <LoginProfesor /> } />
        {/* <Route path="/profesor/recursos" element={ MenuProfesor(<Cursos />) } /> */}
        <Route path="/profesor" element={ MenuProfesor(<Cursos />) } />

        <Route path="/profesor/elegirRecursos" element={ tokenProfesor ? MenuProfesor(<ElegirRecursos />) : <LoginProfesor /> } />
        {/* <Route path="/profesor/elegirRecursos" element={ MenuProfesor(<ElegirRecursos />) } /> */}

        <Route path="/profesor/elegirAlumnos" element={ tokenProfesor ? MenuProfesor(<ElegirAlumnos />) : <LoginProfesor /> } />
        {/* <Route path="/profesor/elegirAlumnos" element={ MenuProfesor(<ElegirAlumnos />) } /> */}

        {/* ALUMNO */}
        <Route path="/alumno" element={ tokenAlumno ? MenuAlumnoNuevo(<PerfilAlumno />, "true") : <LoginAlumno /> } />
        {/* <Route path="/alumno" element={ MenuAlumnoNuevo(<PerfilAlumno />, "true") } /> */}

        <Route path="/alumno/categoria/:id" element={ tokenAlumno ? MenuAlumnoNuevo(<DashboardAlumno />, "true") : <LoginAlumno /> } />
        {/* <Route path="/alumno/categoria/:id" element={ MenuAlumnoNuevo(<DashboardAlumno />, "true") } /> */}

        <Route path="/alumno/login" element={ tokenAlumno ? MenuAlumnoNuevo(<PerfilAlumno />, "true") : <LoginAlumno /> } />
        {/* <Route path="/alumno/login" element={ <LoginAlumno /> } /> */}

        <Route path="/alumno/cursos" element={ tokenAlumno ? MenuAlumnoNuevo(<CursosAlumno />) : <LoginAlumno /> } />
        {/* <Route path="/alumno/cursos" element={ MenuAlumnoNuevo(<CursosAlumno />) } /> */}

        <Route path="/alumno/perfil" element={ tokenAlumno ? MenuAlumnoNuevo(<PerfilAlumno />, "true") : <LoginAlumno /> } />
        {/* <Route path="/alumno/perfil" element={ MenuAlumnoNuevo(<PerfilAlumno />, "true") } /> */}

        <Route path="/alumno/archivos" element={ tokenAlumno ? MenuAlumnoNuevo(<ArchivosAlumno />, "true") : <LoginAlumno /> } />
        {/* <Route path="/alumno/archivos" element={ MenuAlumnoNuevo(<ArchivosAlumno />, "true") } /> */}

        {/* JUEGOS */}
        <Route path="/alumno/juegos" element={ tokenAlumno ? MenuAlumnoNuevo(<ScreenGame />, "false") : <LoginAlumno /> } />
        {/* <Route path="/alumno/juegos" element={ MenuAlumnoNuevo(<ScreenGame />, "false") } /> */}

      </Routes>
    </BrowserRouter>
  );
};