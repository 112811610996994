import React, { useState, useEffect } from "react";

// APIS
import { getAssignCouseByIdAssign, getAssignCouseByIdAssignCategoria } from "../../../service/assignTeacher";

// COMPONENTES
import CardGame from '../../../components/alumno/cardGame';

// IMG PROFILES
import profile_img_1 from '../../../assets/img/profile_1.png';
import profile_img_2 from '../../../assets/img/profile_2.png';
import profile_img_3 from '../../../assets/img/profile_3.png';

import { useLocation } from "react-router-dom";

const DashboardAlumno = () => {
    // CONSTANTES
    const location = useLocation();
    const [cursos, setCursos] = useState();
    const { categoryCurso } = location.state ?? {};

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, [categoryCurso]);


    // OBTIENE LOS DATOS
    const loadData = async () => {
        if (!categoryCurso) {
            const response = await getAssignCouseByIdAssign(localStorage.getItem("alumnoID"));
            console.log(response);

            if (response.assignments) {
                setCursos(response.assignments);
            }
            
        }else{
            console.log(categoryCurso);
            
            const response = await getAssignCouseByIdAssignCategoria(localStorage.getItem("alumnoID"), categoryCurso);
            console.log(response);
            
            if (response.assignments) {
                setCursos(response.assignments);
            }
        }
    };

    return (
        <div className='main__container__'>
            <div className='main__container__left'>
                {/* <span className='main__titulo__'>Recursos Inscritos</span> */}
                <span className='main__titulo__'>{categoryCurso ? categoryCurso : "Recursos Inscritos"}</span>
                <div className='main__container__cards'>
                    <div className='main__row'>
                        {cursos && cursos.length > 0 ? (
                            cursos.map((curso) => (
                                <div className='main__col' key={curso.id}>   
                                    <CardGame titulo={curso.assign.course.title} nivel={curso.assign.course.nivel} id={curso.id} idAssign={curso.assign.id}/>
                                </div>
                            ))
                        ) : (
                            <h5 className="text-center">
                                No hay datos disponibles.
                            </h5>
                        )}
                    </div>

                </div>
            </div>
            {/* <div className='main__container__right'>
                <span className='main__titulo__'>Profesores</span>
                <div className='main__container__profiles'>
                    <img src={profile_img_1} alt='' />
                    <img src={profile_img_2} alt='' />
                    <img src={profile_img_3} alt='' />
                </div>
                <span className='main__titulo__'>Comentarios</span>
                <div className='main__container__mensajes'>
                    <div className='main__item__mensaje'>
                        <span className='main__titulo__mensajes'>Richard Ortiz</span>
                        <span className='main__subtitulo__mensajes'>Mejore en el juego numero 1 Laberinto</span>
                        <span className='main__date__mensajes'>10:00 PM</span>
                    </div>
                    <div className='main__item__mensaje'>
                        <span className='main__titulo__mensajes'>Richard Ortiz</span>
                        <span className='main__subtitulo__mensajes'>Mejore en el juego numero 1 Laberinto</span>
                        <span className='main__date__mensajes'>10:00 PM</span>
                    </div>
                    <div className='main__item__mensaje'>
                        <span className='main__titulo__mensajes'>Richard Ortiz</span>
                        <span className='main__subtitulo__mensajes'>Mejore en el juego numero 1 Laberinto</span>
                        <span className='main__date__mensajes'>10:00 PM</span>
                    </div>

                </div>
            </div> */}

        </div>
    )
}

export default DashboardAlumno