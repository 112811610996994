import React, { useState, useEffect, useRef } from 'react';
import './css/AlphabetSoup.css';
import { InsertProgresoEstudiante, updateProgreso } from '../../../service/progresoEstudiante';

// CONFETI
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

const palabras = ['GATO', 'PEZ', 'PECES','LEON', 'ARBOL', 'PERRO', 'PAPA', 'MAMA', 'SOL', 'ELEFANTE', 'CARRO', 'FLORES', 'BALLENA', 'LIBRO', 'ARCOIRIS', 'COHETE', 'AVION','LAPIZ'];

const AlphabetSoup = ({ nivel, id }) => {
    const [words, setWords] = useState([]);

    useEffect(() => {
        const palabrasGeneradas = {
            1: [...palabras].sort(() => 0.5 - Math.random()).slice(0, 5),
            2: [...palabras].sort(() => 0.5 - Math.random()).slice(0, 10),
            3: [...palabras].sort(() => 0.5 - Math.random()).slice(0, 15)
        };

        setWords(palabrasGeneradas[nivel] || []);
    }, [nivel]);

    const matrixSize = 15;

    const [matrix, setMatrix] = useState([]);
    const [generate, setGenerate] = useState(false);
    const [selectedCells, setSelectedCells] = useState([]);
    const [foundWords, setFoundWords] = useState([]);
    const [wordColors, setWordColors] = useState({});
    const [isDragging, setIsDragging] = useState(false);

    // Lista de colores predefinidos
    const colors = [
        '#FF5733', // Rojo anaranjado
        '#33FF57', // Verde lima
        '#3357FF', // Azul brillante
        '#F3FF33', // Amarillo limón
        '#FF33A6', // Rosa intenso
        '#FFD700', // Dorado brillante
        '#FF8C00', // Naranja oscuro
        '#ADFF2F', // Verde amarillento
        '#00CED1', // Turquesa oscuro
        '#FF4500', // Naranja rojizo
        '#DA70D6', // Orquídea
        '#FF1493', // Rosa profundo
        '#8A2BE2', // Azul violeta
        '#20B2AA', // Verde mar
        '#FF6347', // Tomate
        '#FF6F61', // Coral oscuro
        '#FF1493', // Rosa profundo
        '#4B0082', // Índigo
        '#FFB6C1', // Rosa claro
        '#40E0D0', // Turquesa
        '#FF69B4', // Rosa fuerte
        '#8B4513', // Marrón rojizo
        '#D2691E', // Chocolate
        '#CD5C5C', // Rojo claro
        '#B22222', // Rojo ladrillo
        '#32CD32', // Verde lima oscuro
        '#FFDAB9', // Durazno
        '#FF7F50', // Coral
        '#F08080', // Rojo claro
        '#E0FFFF', // Azul claro
        '#B0E57C', // Verde pastel
        '#F0E68C', // Amarillo caqui
        '#DDA0DD', // Ciruela
        '#C71585', // Rosa medio
        '#FF4500', // Naranja rojizo
        '#FF6347', // Tomate
        '#6A5ACD', // Azul pálido
        '#A52A2A', // Marrón
        '#DAA520', // Dorado
        '#FF00FF', // Fucsia
        '#8B008B', // Púrpura oscuro
        '#0000FF', // Azul
        '#00FF00'  // Verde brillante
    ];
    

    // CONSTANTES
    const [timeTaken, setTimeTaken] = useState(null);
    const [progreso, setProgreso] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [insertar, setInsertar] = useState(true);
    const hasInsertedProgress = useRef(false);

    // CONFETI
    const [showConfetti, setShowConfetti] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (words.length > 0 && !generate) {
            const generateMatrix = () => {
                const mat = Array.from({ length: matrixSize }, () => Array(matrixSize).fill(''));

                const placeWordHorizontally = (word) => {
                    let placed = false;
                    while (!placed) {
                        const row = Math.floor(Math.random() * matrixSize);
                        const col = Math.floor(Math.random() * (matrixSize - word.length + 1));
                        const canPlace = mat[row].slice(col, col + word.length).every(cell => cell === '');
                        if (canPlace) {
                            for (let i = 0; i < word.length; i++) {
                                mat[row][col + i] = word[i];
                            }
                            placed = true;
                        }
                    }
                };

                const placeWordVertically = (word) => {
                    let placed = false;
                    while (!placed) {
                        const row = Math.floor(Math.random() * (matrixSize - word.length + 1));
                        const col = Math.floor(Math.random() * matrixSize);
                        const canPlace = mat.slice(row, row + word.length).every(row => row[col] === '');
                        if (canPlace) {
                            for (let i = 0; i < word.length; i++) {
                                mat[row + i][col] = word[i];
                            }
                            placed = true;
                        }
                    }
                };

                words.forEach(word => {
                    Math.random() > 0.5 ? placeWordHorizontally(word) : placeWordVertically(word);
                });

                for (let i = 0; i < matrixSize; i++) {
                    for (let j = 0; j < matrixSize; j++) {
                        if (mat[i][j] === '') {
                            mat[i][j] = String.fromCharCode(Math.floor(Math.random() * 26) + 65);
                        }
                    }
                }

                return mat;
            };

            setMatrix(generateMatrix());
            setGenerate(true);
        }
    }, [generate, words]);

    useEffect(() => {
        if (insertar && !hasInsertedProgress.current) {
            hasInsertedProgress.current = true;
            (async () => {
                const defaultFields = {
                    idAssignTeacher: id,
                    duracion: '0',
                    completados: 0,
                };
                const response = await InsertProgresoEstudiante(defaultFields);
                setProgreso(response.progreso_estudiante);
                console.log(response.progreso_estudiante);
            })();
        }
    }, [insertar, startTime, id]);

    const handleMouseDown = (rowIndex, colIndex) => {
        setIsDragging(true);
        setSelectedCells([{ rowIndex, colIndex }]);
    };

    const handleTouchStart = (rowIndex, colIndex) => {
        setIsDragging(true);
        setSelectedCells([{ rowIndex, colIndex }]);
    };

    const handleMouseEnter = (rowIndex, colIndex) => {
        if (isDragging) {
            setSelectedCells(prev => [...prev, { rowIndex, colIndex }]);
        }
    };

    const getCellFromTouch = (x, y) => {
        const rows = document.querySelectorAll('.matrix .row');
        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll('.cell');
            for (let colIndex = 0; colIndex < cells.length; colIndex++) {
                const cell = cells[colIndex];
                const rect = cell.getBoundingClientRect();
                if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
                    return { rowIndex, colIndex };
                }
            }
        }
        return null;
    };

    const handleTouchMove = (event) => {
        // const touch = e.touches[0];
        // const rowIndex = Math.floor(touch.clientY / 300);
        // const colIndex = Math.floor(touch.clientX / 300);

        // console.log(rowIndex, colIndex);
        
        // if (isDragging) {
        //     setSelectedCells(prev => [...prev, { rowIndex, colIndex }]);
        // }

        if (isDragging) {
            const touch = event.touches[0];
            const cell = getCellFromTouch(touch.clientX, touch.clientY);
            if (cell) {
                const { rowIndex, colIndex } = cell;
                // Evita agregar celdas duplicadas
                if (!selectedCells.some(c => c.rowIndex === rowIndex && c.colIndex === colIndex)) {
                    setSelectedCells(prev => [...prev, { rowIndex, colIndex }]);
                }
            }
        }
    };

    const handleMouseUp = () => {
        if (!startTime) setStartTime(Date.now());
        setIsDragging(false);

        const selectedLetters = selectedCells.map(({ rowIndex, colIndex }) => matrix[rowIndex][colIndex]).join('');
        console.log('Selected Letters:', selectedLetters);

        const matchingWord = words.find(word => selectedLetters.endsWith(word));
        console.log('Matching Word:', matchingWord);

        if (matchingWord) {
            const color = colors[Math.floor(Math.random() * colors.length)];
            // const color = "#FF5733";
            setFoundWords(prev => [...prev, matchingWord]);

            const wordCells = findWordCells(matchingWord);
            console.log('Word Cells:', wordCells);

            setWordColors(prev => {
                const audio = new Audio('/sounds/badge-coin-win-14675.mp3');
                audio.play();
                const newColors = { ...prev };
                wordCells.forEach(({ rowIndex, colIndex }) => {
                    newColors[`${rowIndex}-${colIndex}`] = color;
                });
                return newColors;
            });

            // Verificar si esta palabra es la última que falta
            const allWordsFound = words.every(word => foundWords.includes(word) || word === matchingWord);
            if (allWordsFound) {
                setShowConfetti(true);
                setShowMessage(true);

                const audio = new Audio('/sounds/applause-small-audience-97257.mp3');
                audio.play();

                const endTime = Date.now();
                setTimeTaken((endTime - startTime) / 1000);

                const defaultFields = {
                    id: progreso.id,
                    duracion: formatTime((endTime - startTime) / 1000),
                    completados: 1,
                };

                updateProgreso(defaultFields).then(response => {
                    console.log(response);
                });

                setTimeout(() => {
                    setShowConfetti(false);
                    setShowMessage(false);

                    // window.location = "/alumno";
                    navigate('/alumno');
                }, 6000);
            }
        }
        setSelectedCells([]);
    };

    const handleTouchEnd = () => {
        if (!startTime) setStartTime(Date.now());
        setIsDragging(false);

        const selectedLetters = selectedCells.map(({ rowIndex, colIndex }) => matrix[rowIndex][colIndex]).join('');
        console.log('Selected Letters:', selectedLetters);

        const matchingWord = words.find(word => selectedLetters.endsWith(word));
        console.log('Matching Word:', matchingWord);

        if (matchingWord) {
            const color = colors[Math.floor(Math.random() * colors.length)];
            // const color = "#FF5733";
            setFoundWords(prev => [...prev, matchingWord]);

            const wordCells = findWordCells(matchingWord);
            console.log('Word Cells:', wordCells);

            setWordColors(prev => {
                const audio = new Audio('/sounds/badge-coin-win-14675.mp3');
                audio.play();
                const newColors = { ...prev };
                wordCells.forEach(({ rowIndex, colIndex }) => {
                    newColors[`${rowIndex}-${colIndex}`] = color;
                });
                return newColors;
            });

            // Verificar si esta palabra es la última que falta
            const allWordsFound = words.every(word => foundWords.includes(word) || word === matchingWord);
            if (allWordsFound) {
                setShowConfetti(true);
                setShowMessage(true);

                const audio = new Audio('/sounds/applause-small-audience-97257.mp3');
                audio.play();

                const endTime = Date.now();
                setTimeTaken((endTime - startTime) / 1000);

                const defaultFields = {
                    id: progreso.id,
                    duracion: formatTime((endTime - startTime) / 1000),
                    completados: 1,
                };

                updateProgreso(defaultFields).then(response => {
                    console.log(response);
                });

                setTimeout(() => {
                    setShowConfetti(false);
                    setShowMessage(false);

                    // window.location = "/alumno";
                    navigate('/alumno');
                }, 6000);
            }
        }
        setSelectedCells([]);
    };



    const findWordCells = (word) => {
        const cells = [];

        // Buscar horizontalmente
        matrix.forEach((row, rowIndex) => {
            let startIndex = row.join('').indexOf(word);
            while (startIndex !== -1) {
                for (let i = 0; i < word.length; i++) {
                    cells.push({ rowIndex, colIndex: startIndex + i });
                }
                startIndex = row.join('').indexOf(word, startIndex + 1);
            }
        });

        // Buscar verticalmente
        for (let colIndex = 0; colIndex < matrixSize; colIndex++) {
            const column = matrix.map(row => row[colIndex]);
            let startIndex = column.join('').indexOf(word);
            while (startIndex !== -1) {
                for (let i = 0; i < word.length; i++) {
                    cells.push({ rowIndex: startIndex + i, colIndex });
                }
                startIndex = column.join('').indexOf(word, startIndex + 1);
            }
        }

        return cells;
    };

    const isSelected = (rowIndex, colIndex) => {
        return selectedCells.some(cell => cell.rowIndex === rowIndex && cell.colIndex === colIndex);
    };

    const getCellColor = (rowIndex, colIndex) => {
        const cellKey = `${rowIndex}-${colIndex}`;
        const color = wordColors[cellKey] || (isSelected(rowIndex, colIndex) ? 'rgba(0, 0, 255, 0.3)' : 'transparent');
        // console.log(`Cell ${cellKey} Color:`, color);
        return color;
    };

    function formatTime(tiempo) {
        const hours = Math.floor(tiempo / 3600);
        const minutes = Math.floor((tiempo % 3600) / 60);
        const seconds = tiempo % 60;

        const pad = (num) => (num < 10 ? `0${num}` : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };

    return (
        <div className="alphabet-soup">
            <div className='container_soup'>
                <div className="words-to-find">
                    <ul className={`${words.length === 5 ? 'five-elements' : words.length === 10 ? 'ten-elements' : words.length === 15 ? 'fifteen-elements' : ''}`}>
                        {words.map((word, index) => (
                            <li
                                key={index}
                                className={foundWords.includes(word) ? 'found' : ''}
                            >
                                {word}
                            </li>
                        ))}
                    </ul>
                </div>

                <div
                    className="matrix"
                    onMouseUp={handleMouseUp}
                    onTouchEnd={handleTouchEnd}

                >
                    {matrix.map((row, rowIndex) => (
                        <div className="row" key={rowIndex}>
                            {row.map((letter, colIndex) => (
                                <span
                                    className={`cell ${isSelected(rowIndex, colIndex) ? 'selected' : ''}`}
                                    key={colIndex}
                                    style={{ backgroundColor: getCellColor(rowIndex, colIndex) }}

                                    onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                                    onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                                    
                                    onTouchStart={() => handleTouchStart(rowIndex, colIndex)}
                                    onTouchMove={(e) => handleTouchMove(e)}
                                >
                                    {letter}
                                </span>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            {showConfetti && <Confetti />}
            {showMessage && (
                <div style={messageStyle}>
                    ¡Ganaste!
                </div>
            )}
        </div>
    );
};


const messageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    fontSize: '2rem',
    zIndex: 1000,
    textAlign: 'center',
};

export default AlphabetSoup;
