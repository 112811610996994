import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// CSS
import "../../assets/css/navbarProfesor.css";
import logo from "../../assets/img/logo_3.png";

const Navbar = ({ content }) => {
    // CONSTANTES
    const [nameTeacher, setNameTeacher] = useState();
    const location = useLocation();

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = () => {
        const name = localStorage.getItem("nameAdmin");
        setNameTeacher(name);
    };
    // SALIR
    const handleLogout = () => {
        window.location = "/";
        localStorage.removeItem("token");
    };

    return (
        <>
            <div className="principal">
                <div className="sidebar">
                    <nav>
                        <div>
                            <div className="profile">
                            <img src={logo} alt="" className="logo"/>
                                <h3>{nameTeacher}</h3>
                            </div>
                        </div>
                        <hr />
                        <ul>
                            <Link to={"/profesores"}>
                                <li
                                    className={`${location.pathname === "/profesores" ? "active" : ""
                                        }`}
                                >
                                    <i class="fa-solid fa-user-tie"></i>
                                    Profesores
                                </li>
                            </Link>
                            <Link to={"/alumnos"}>
                                <li
                                    className={`${location.pathname === "/alumnos" ? "active" : ""
                                        }`}
                                >
                                    <i className="fa-solid fa-users"></i>
                                    Alumnos
                                </li>
                            </Link>
                            <Link to={"/cursos"}>
                                <li
                                    className={`${location.pathname === "/cursos" ? "active" : ""
                                        }`}
                                >
                                    <i class="fa-solid fa-gamepad"></i>
                                    Recursos
                                </li>
                            </Link>
                            <Link to={"/asignamiento"}>
                                <li
                                    className={`${location.pathname === "/asignamiento" ? "active" : ""
                                        }`}
                                >
                                    <i class="fa-solid fa-person-harassing"></i>
                                    Asignamiento Recurso
                                </li>
                            </Link>
                            <Link to={"/asignamientoProfesor"}>
                                <li
                                    className={`${location.pathname === "/asignamientoProfesor"
                                            ? "active"
                                            : ""
                                        }`}
                                >
                                    <i class="fa-solid fa-boxes-stacked"></i>
                                    Asignamiento Alumno
                                </li>
                            </Link>
                        </ul>
                    </nav>

                    <div className="salir">
                        <hr />

                        <ul>
                            <Link to={"/"}>
                            <li onClick={handleLogout}>
                                <i class="fa-solid fa-right-from-bracket"></i>
                                <a href="#">Salir</a>
                            </li>
                            </Link>
                        </ul>
                    </div>
                </div>
                <div className="content">{content}</div>
            </div>
        </>
    );
};

export default Navbar;
