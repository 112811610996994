import React, { useEffect } from 'react'
// APIS
import { getAssignTeacherById } from '../../../service/assignTeacher';

const FrmNota = ({ fields, setFields, id }) => {
    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getAssignTeacherById(id);
        if (response.assignmentTeacher) {
            setFields(response.assignmentTeacher);
        }
    };

    // AGREGA VALOR A FIELDS
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Apreciación
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="note"
                    value={fields.note}
                    onChange={handleInputChange}
                >
                    <option value="No asignado.">
                        No asignado.
                    </option>
                    <option value="Domina los Aprendizajes.">
                        Domina los Aprendizajes.
                    </option>
                    <option value="Alcanza los Aprendizajes.">
                        Alcanza los Aprendizajes.
                    </option>
                    <option value="Está próximo a alcanzar los Aprendizajes.">
                        Está próximo a alcanzar los Aprendizajes.
                    </option>
                    <option value="No alcanza los Aprendizajes.">
                        No alcanza los Aprendizajes.
                    </option>

                </select>
            </div>
        </>
    )
}

export default FrmNota