import React, { useState } from "react";

const FrmStudent = ({ fields, setFields }) => {
    const [show, setShow] = useState(false);

    const showPassword = () => {
        setShow((prevState) => !prevState);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <div className="mb-3">
                <label htmlFor="nameStudent" className="form-label">
                    Nombres
                </label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresar nombres."
                    name="nameStudent"
                    value={fields.nameStudent}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="lastNameStudent" className="form-label">
                    Apellidos
                </label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresar apellidos."
                    name="lastNameStudent"
                    value={fields.lastNameStudent}
                    onChange={handleInputChange}
                />
            </div>

            <div className="row">

                <div className="col-7">
                    <div className="mb-3">
                        <label htmlFor="addressStudent" className="form-label">
                            Dirección de Domicilio
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingresar dirección."
                            name="addressStudent"
                            value={fields.addressStudent}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="col-5">
                    <div className="mb-3">
                        <label htmlFor="phoneStudent" className="form-label">
                            Celular
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingresar celular."
                            name="phoneStudent"
                            value={fields.phoneStudent}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-7">
                    <div className="mb-3">
                        <label htmlFor="nameRepresentante" className="form-label">
                            Nombre del Representante
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingresar nombre del Representante."
                            name="nameRepresentante"
                            value={fields.nameRepresentante}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="col-5">
                    <div className="mb-3">
                        <label htmlFor="phoneRepresentante" className="form-label">
                            Celular del Representante
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingresar celular del Representante."
                            name="phoneRepresentante"
                            value={fields.phoneRepresentante}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="emailStudent" className="form-label">
                    Correo Electrónico
                </label>
                <input
                    type="email"
                    className="form-control"
                    placeholder="name@example.com"
                    name="emailStudent"
                    value={fields.emailStudent}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="passwordStudent" className="form-label">
                    Contraseña
                </label>
                <div className="input-group">
                    <input
                        type={show ? "text" : "password"}
                        className="form-control"
                        placeholder="Contraseña..."
                        name="passwordStudent"
                        value={fields.passwordStudent}
                        onChange={handleInputChange}
                    />
                    <button className="btn btn-secondary" onClick={showPassword}>
                        {show ? (
                            <i className="fa-solid fa-eye-slash"></i>
                        ) : (
                            <i className="fa-solid fa-eye"></i>
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default FrmStudent;
