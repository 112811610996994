import React, { useState, useEffect } from "react";
import { getProgresoEstudianteById } from "../../../service/progresoEstudiante";

const TableProgreso = ({id}) => {
    const [progresos, setProgresos] = useState([]);

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getProgresoEstudianteById(id);
        if (response.progreso_estudiante) {
            setProgresos(response.progreso_estudiante);
        }
        console.log(response.progreso_estudiante);
    };

    return (
        <div>
            <h5>Progreso</h5>

            <div className="row mt-3">
                <div className="col-12 ">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Intento</th>
                                    <th>Duracion</th>
                                    <th>Completados</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {progresos && progresos.length > 0 ? (
                                    progresos.map((progreso, index) => (
                                        <tr key={progreso.id}>
                                            <td>{index + 1}</td>
                                            <td>{progreso.duracion}</td>
                                            <td>
                                                {/* {progreso.completados} */}
                                                {progreso.completados ? <span className="badge bg-success">completados</span> : <span className="badge bg-danger">incompleto</span>}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            No hay datos disponibles.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableProgreso