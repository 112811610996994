import React, { useState } from "react";
// APIS
import { register } from "../../service/login";

// ALERTAS
import {
    AlertError
} from "../../components/alerts";
import { Link } from "react-router-dom";


const Register = () => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        idUser: 0,
        name: "",
        email: "",
        password: "",
    };

    // CONSTANTES
    const [fields, setFields] = useState(defaultFields);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    // REGISTRARSE
    const registrarse = async () => {
        const response = await register(fields);
        if (response.user) {
            window.location = '/';

        } else {
            return await AlertError(response.message);
        }
    }

    return (
        <>
            <div className="login-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 login-sec">
                            <h2 className="text-center">Registrarse</h2>
                            <form className="login-form">
                                <div className="form-group mb-3 px-4">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="text-uppercase"
                                    >
                                        Nombre
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="name"
                                        value={fields.name}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group mb-3 px-4">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="text-uppercase"
                                    >
                                        Correo
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="email"
                                        value={fields.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group mb-3 px-4">
                                    <label
                                        htmlFor="exampleInputPassword1"
                                        className="text-uppercase"
                                    >
                                        Contraseña
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder=""
                                        name="password"
                                        value={fields.password}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group mb-4 px-4">
                                    <span className="me-2">¿Ya tiene una cuenta?</span>
                                    <Link to={"/"} >Iniciar Sesion</Link>
                                    
                                </div>
                                <button type="button" className="btn btn-login float-end me-4" onClick={registrarse}>
                                        Registrarse
                                    </button>
                            </form>
                        </div>
                        <div className="col-md-8 banner-sec">
                            <div
                                id="carouselExampleIndicators"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img
                                            className="d-block w-100"
                                            src="https://static.pexels.com/photos/33972/pexels-photo.jpg"
                                            alt="First slide"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
