import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// CSS
import "../../assets/css/navbarNuevo.css";

// IMAGES
import graduation_cap from "../../assets/img/Graduation_Cap.png";
import logo from "../../assets/img/logo_3.png";
import dashboard_icon from "../../assets/img/dashboard.png";
import notebook_icon from "../../assets/img/notebook.png";
import logout_icon from "../../assets/img/logout.png";
import profile_img from "../../assets/img/profile.png";

import student_img from "../../assets/img/student.png";
import scholarcap_img from "../../assets/img/scholarcap.png";
import backpack_img from "../../assets/img/backpack.png";
import { getAssignCouseByIdAssign } from "../../service/assignTeacher";

import { useNavigate } from 'react-router-dom';

const NavbarNuevo = ({ content, banner }) => {
    // FECHA ACTUAL FORMATEADA 
    const formatDate = (date) => {
        const months = [
            "enero", "febrero", "marzo", "abril", "mayo", "junio",
            "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
        ];

        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day} de ${month} de ${year}`;
    }

    // CONSTANTES
    const [cursosAgrupados, setCursosAgrupados] = useState();
    const [nameStudent, setNameStudent] = useState();
    const location = useLocation();
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);
    const navigate = useNavigate();

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const name = localStorage.getItem("alumnoName");
        setNameStudent(toTitleCase(name));

        const id = localStorage.getItem("alumnoID");

        const response = await getAssignCouseByIdAssign(id);
        if (response.assignments) {
            const groupedByCategory = response.assignments.reduce((acc, item) => {
                const category = item.assign.course.category;
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(item);
                return acc;
            }, {});
            setCursosAgrupados(groupedByCategory);
            console.log(groupedByCategory);

        }

    };

    // SALIR
    const handleLogout = () => {
        localStorage.removeItem('tokenAlumno');
        navigate('/alumno/login');
        // window.location = "/alumno/login";

    };

    // CONVERTIR A CAPITALIZE TEXTO
    const toTitleCase = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }


    return (
        <div className="container__">
            <div className="sidebar__">
                <div>
                    <div className="container__logo__">
                        <div className="logo__">
                            <img src={logo} alt="" />
                        </div>
                    </div>

                    <div className="menu__">

                        <li>
                            <img src={dashboard_icon} className={`${location.pathname === "/alumno" ? "active__" : ""}`} alt="" />
                            <Link to={"/alumno"} state={{ categoryCurso: "" }} className={`${location.pathname === "/alumno" ? "active__" : ""}`}>
                                Principal
                            </Link>
                        </li>
                        {cursosAgrupados && Object.keys(cursosAgrupados).map((category) => (
                            <li key={category}>
                                <img src={dashboard_icon} className={`${location.pathname === "/alumno/categoria/" + cursosAgrupados[category][0].assign.course.id ? "active__" : ""}`} alt="" />
                                <Link to={"/alumno/categoria/" + cursosAgrupados[category][0].assign.course.id} state={{ categoryCurso: category}} className={`${location.pathname === "/alumno/categoria/" + cursosAgrupados[category][0].assign.course.id ? "active__" : ""}`}>
                                    {category}
                                </Link>
                            </li>
                        ))}
                    </div>
                </div>

                <div className="menu__">
                    {/* <Link to={"/alumno/login"}> */}
                        <li onClick={handleLogout}>
                            <img src={logout_icon} className="active__" alt="" />
                            <a className="active__" href="">
                                Salir
                            </a>
                        </li>
                    {/* </Link> */}
                </div>
            </div>

            <div className="main__">
                <div className="header__">
                    <div className="profile__">
                        <img src={profile_img} alt="" />
                        <div className="names__">
                            <Link to={"/alumno/perfil"} style={{ textDecoration: "none" }}>
                                <span className="bold__">

                                    {nameStudent}
                                </span>
                            </Link>

                            {/* <span>{sectionStudent} grado {gradoStudent}</span> */}
                        </div>
                    </div>
                </div>
                {banner == "true" ?
                    <div className="banner__">
                        <div className="texto__">
                            <div className="date__">{formattedDate}</div>
                            <div className="info__">
                                <span className="titulo__">¡Bienvenido de nuevo, {nameStudent}!</span>
                                <span className="subtitulo__">Mantente siempre actualizado en tu portal de estudiantes</span>
                            </div>
                        </div>
                        <img className="student__img__" src={student_img} alt="" />
                        <img className="scholarcap__img__" src={scholarcap_img} alt="" />
                        <img className="backpack__img__" src={backpack_img} alt="" />
                    </div>
                    : ''}

                {content}
            </div>
        </div>
    );
};

export default NavbarNuevo;
