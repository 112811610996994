import { baseURLServicio } from '../config';
import * as METHOD from '../methods';
const URL = `${baseURLServicio}`;

export const getStudents = async () => {
    try {
        const response = await fetch(`${URL}/students`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getStudentById = async (id) => {
    try {
        const response = await fetch(`${URL}/students/${id}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const InsertOrUpdateStudent = async (body) => {
    try {
        const method = body.id && body.id !== 0 ? 'PUT' : 'POST';

        let response = ""
        if (method === 'PUT') {
            response = await fetch(`${URL}/students/${body.id}`, METHOD.PUT(body));
        }else{
            response = await fetch(`${URL}/students`, METHOD.POST(body));
        }

        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteStudentApi = async (id) => {
    try {
        const response = await fetch(`${URL}/students/${id}`, METHOD.DELETE());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};