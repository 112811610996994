import React from "react";

const FrmCourse = ({ fields, setFields }) => {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <div className="mb-3">

                <label htmlFor="category" className="form-label">
                    Categoria Juego
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="categoryCourse"
                    value={fields.categoryCourse}
                    onChange={handleInputChange}
                >
                    <option value="Juegos de Concentración">
                        Juegos de Concentración
                    </option>
                    <option value="Juegos de Atención">
                        Juegos de Atención
                    </option>
                    <option value="Juegos de Actividades Matemáticas">
                        Juegos de Actividades Matemáticas
                    </option>

                </select>
            </div>


            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Titulo Recurso
                </label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresar titulo del curso."
                    name="titleCourse"
                    value={fields.titleCourse}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="nivel" className="form-label">
                    Nivel
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="nivelCourse"
                    value={fields.nivelCourse}
                    onChange={handleInputChange}
                >
                    <option value="1">
                        1
                    </option>
                    <option value="2">
                        2
                    </option>
                    <option value="3">
                        3
                    </option>

                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="desCourse" className="form-label">
                    Descripción
                </label>
                <textarea
                    className="form-control"
                    placeholder="Descripción..."
                    style={{ resize: "none", height: "100px" }}
                    name="desCourse"
                    value={fields.desCourse}
                    onChange={handleInputChange}>

                </textarea>

            </div>
        </>
    );
};

export default FrmCourse;
