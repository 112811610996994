import React, { useState, useEffect, useRef } from 'react';
import './css/BuscaLaDiferencia.css';

import imagenCompleta1 from "./img/diferencia_original_1.png";
import imagenConDiferencias1 from "./img/diferencia_copia_1.png";

import imagenCompleta2 from "./img/diferencia_original_2.png";
import imagenConDiferencias2 from "./img/diferencia_copia_2.png";

import imagenCompleta3 from "./img/diferencia_original_3.png";
import imagenConDiferencias3 from "./img/diferencia_copia_3.png";

import imagenX from "./img/error.webp";

// CONFETI
import Confetti from 'react-confetti';
import { InsertProgresoEstudiante, updateProgreso } from '../../../service/progresoEstudiante';

import { useNavigate } from 'react-router-dom';

const BuscaLaDiferencia = ({ nivel, id }) => {

    const nivelDatos = {
        1: {
            imagenCompleta: imagenCompleta1,
            imagenConDiferencias: imagenConDiferencias1,
            diferencias: [
                { top: '90px', left: '350px' },
                { top: '55px', left: '200px' },
                { top: '110px', left: '273px' },
                { top: '165px', left: '120px' },
                { top: '160px', left: '310px' },
                { top: '310px', left: '370px' },
            ],
        },
        2: {
            imagenCompleta: imagenCompleta2,
            imagenConDiferencias: imagenConDiferencias2,
            diferencias: [
                { top: '30px', left: '210px' },
                { top: '205px', left: '120px' },
                { top: '245px', left: '166px' },
                { top: '213px', left: '47px' },
                { top: '215px', left: '235px' },
                { top: '243px', left: '395px' },
                { top: '293px', left: '305px' },
                { top: '325px', left: '125px' },
                { top: '130px', left: '225px' },
                { top: '255px', left: '280px' },
            ],
        },
        3: {
            imagenCompleta: imagenCompleta3,
            imagenConDiferencias: imagenConDiferencias3,
            diferencias: [
                { top: '90px', left: '370px' },
                { top: '10px', left: '140px' },
                { top: '165px', left: '240px' },
                { top: '160px', left: '83px' },
                { top: '260px', left: '145px' },
                { top: '265px', left: '185px' },
                { top: '170px', left: '45px' },
                { top: '260px', left: '365px' },
                { top: '255px', left: '270px' },
                { top: '250px', left: '338px' },
            ],
        },
    };

    const { imagenCompleta, imagenConDiferencias, diferencias } = nivelDatos[nivel] || nivelDatos[1]; // Default to level 1 if not found


    const [encontradas, setEncontradas] = useState([]);
    const [errores, setErrores] = useState([]);
    const [juegoGanado, setJuegoGanado] = useState(false);

    // CONSTANTES
    const [timeTaken, setTimeTaken] = useState(null);
    const [progreso, setProgreso] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [insertar, setInsertar] = useState(true);
    const hasInsertedProgress = useRef(false);

    // CONFETI
    const [showConfetti, setShowConfetti] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const imagenRef = useRef(null);
    const navigate = useNavigate();

    const ajustarCoordenadas = (x, y) => {
        if (!imagenRef.current) return { x, y };
        const imgElement = imagenRef.current;
        const { width, height } = imgElement.getBoundingClientRect();
        const originalWidth = imgElement.naturalWidth;
        const originalHeight = imgElement.naturalHeight;

        const xRatio = originalWidth / width;
        const yRatio = originalHeight / height;

        return {
            x: x * xRatio,
            y: y * yRatio,
        };
    };

    const manejarClick = (e, indice) => {
        const { offsetX: x, offsetY: y } = e.nativeEvent;
        const { x: xAjustado, y: yAjustado } = ajustarCoordenadas(x, y);

        console.log(x);
        

        if (indice === -1) {
            const diferenciaCorrecta = diferencias.find((dif, i) => {
                const difX = parseInt(dif.left);
                const difY = parseInt(dif.top);
                
                return (
                    !encontradas.includes(i) &&
                    xAjustado >= difX - 15 && xAjustado <= difX + 15 &&
                    yAjustado >= difY - 15 && yAjustado <= difY + 15
                );
            });

            if (diferenciaCorrecta) {
                const audio = new Audio('/sounds/correct-6033.mp3');
                audio.play();
                const indiceCorrecto = diferencias.indexOf(diferenciaCorrecta);
                setEncontradas([...encontradas, indiceCorrecto]);
            } else {
                const nuevoError = { top: `${y - 15}px`, left: `${x + 7}px` };
                setErrores([...errores, nuevoError]);
                const audio = new Audio('/sounds/error-126627.mp3');
                audio.play();
                setTimeout(() => {
                    setErrores(prevErrores => prevErrores.filter(error => error !== nuevoError));
                }, 2000);
            }
        } else if (!encontradas.includes(indice)) {
            const audio = new Audio('/sounds/correct-6033.mp3');
            audio.play();
            setEncontradas([...encontradas, indice]);
        }
    };

    useEffect(() => {
        if (encontradas.length === diferencias.length) {
            setJuegoGanado(true);
        }
    }, [encontradas]);

    useEffect(() => {
        if (insertar && !hasInsertedProgress.current) {
            hasInsertedProgress.current = true;
            (async () => {
                const defaultFields = {
                    idAssignTeacher: id,
                    duracion: '0',
                    completados: 0,
                };
                const response = await InsertProgresoEstudiante(defaultFields);
                setProgreso(response.progreso_estudiante);
                console.log(response.progreso_estudiante);
            })();
        }
    }, [insertar, startTime, id]);

    useEffect(() => {
        if (!startTime) setStartTime(Date.now());
        if (juegoGanado) {
            setShowConfetti(true);
            setShowMessage(true);

            const audio = new Audio('/sounds/applause-small-audience-97257.mp3');
            audio.play();

            const endTime = Date.now();
            setTimeTaken((endTime - startTime) / 1000);

            const defaultFields = {
                id: progreso.id,
                duracion: formatTime((endTime - startTime) / 1000),
                completados: 1,
            };

            updateProgreso(defaultFields).then(response => {
                console.log(response);
            });

            setTimeout(() => {
                setShowConfetti(false);
                setShowMessage(false);

                navigate('/alumno');
            }, 6000);
        }
    }, [juegoGanado]);

    function formatTime(tiempo) {
        const hours = Math.floor(tiempo / 3600);
        const minutes = Math.floor((tiempo % 3600) / 60);
        const seconds = tiempo % 60;

        const pad = (num) => (num < 10 ? `0${num}` : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };

    return (
        <div className="busca-la-diferencia">
            <h1>Busca la diferencia</h1>
            <div className="imagenes">
                <div className="imagen imagen-completa">
                    <img src={imagenCompleta} alt="Imagen Completa" onClick={(e) => manejarClick(e, -1)} ref={imagenRef}/>
                    {diferencias.map((diferencia, index) => (
                        <div
                            key={index}
                            className={`diferencia ${encontradas.includes(index) ? 'encontrada' : ''}`}
                            style={{ top: diferencia.top, left: diferencia.left }}
                            onClick={(e) => manejarClick(e, index)}
                        />
                    ))}
                    {errores.map((error, index) => (
                        <img
                            key={index}
                            src={imagenX}
                            alt="Error"
                            className="error"
                            style={{ top: error.top, left: error.left }}
                        />
                    ))}
                </div>
                <div className="imagen imagen-con-diferencias">
                    <img src={imagenConDiferencias} alt="Imagen con Diferencias" />
                    {diferencias.map((diferencia, index) => (
                        <div
                            key={index}
                            className={`diferencia ${encontradas.includes(index) ? 'encontrada' : ''}`}
                            style={{ top: diferencia.top, left: diferencia.left }}
                        />
                    ))}
                </div>
            </div>
            {showConfetti && <Confetti />}
            {showMessage && (
                <div style={messageStyle}>
                    ¡Ganaste!
                </div>
            )}
        </div>
    );
};


const messageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    fontSize: '2rem',
    zIndex: 1000,
    textAlign: 'center',
};

export default BuscaLaDiferencia;
