import React, { useState, useEffect } from 'react'

// MODAL
import MyModal from '../../../components/modal';

// FORMULARIO
import FrmNota from './frmNota';

// COMENTARIO
import FrmComentario from './frmComentario';

// APIS
import { InsertOrUpdateAssignTeacherNota, getAssignTeacherByIdAssign } from '../../../service/assignTeacher';
import TableProgreso from './tableProgreso';


// PDF
import { jsPDF } from "jspdf";
import "jspdf-autotable";

// IMAGEN PDF
import img_pdf from "../../../assets/img/img_pdf.jpeg"
import { getProgresoEstudianteById } from '../../../service/progresoEstudiante';

const FrmCurso = ({ idAlumno }) => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        id: 0,
        idAssign: 0,
        idStudent: 0,
        note: "",
        comentario: ""
    };

    // CONSTANTES
    const [fields, setFields] = useState(defaultFields);
    const [alumnos, setAlumnos] = useState([]);

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const [id, setId] = useState();
    const [id2, setId2] = useState();
    const [id3, setId3] = useState();

    const [name, setName] = useState("");
    const [recurso, setRecurso] = useState("Ninguno");
    const [grado, setGrado] = useState("Ninguno");

    // MOSTRAR | OCULTAR MODAL
    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);

    const handleShow = (id, name) => {
        setName(name);
        setId(id);
        setShow(true);
    };

    const handleShow2 = (id, name) => {
        setName(name);
        setId2(id);
        setShow2(true);
    };

    const handleShow3 = (id, name) => {
        setName(name);
        setId3(id);
        setShow3(true);
    };

    const generarReporte = async (id, name) => {
        const response = await getProgresoEstudianteById(id);
        const data = response.progreso_estudiante;

        console.log(data);
        
        const doc = new jsPDF();
    
        console.log(data);
    
        // Cargar la imagen (puede ser una URL o una cadena base64)
        const img = new Image();
        img.src = img_pdf;
    
        img.onload = () => {
            // Calcular el ancho y la posición X de la imagen para centrarla
            const imgWidth = 50; // Ajusta el ancho de la imagen
            const pageWidth = doc.internal.pageSize.width;
            const x = (pageWidth - imgWidth) / 2;
    
            // Agregar la imagen al PDF
            doc.addImage(img, 'JPEG', x, 30, imgWidth, 50); // Ajusta la altura según sea necesario
    
            // Calcular la posición Y para la tabla
            const startY = 90;
        
            // Título del PDF
            const title = "Escuela de Educación básica particular";
            const title2 = "centinela iberoamericana cristiana";

            const pageMitad = pageWidth / 2; 

            doc.text(title, pageMitad, 10, { align: 'center' });
            doc.text(title2, pageMitad, 20, { align: 'center' });
    
            // Definir las columnas y las filas de la tabla
            const columns = [
                "Alumno",
                "Recurso",
                "Intento", 
                "Duracion", 
                "Completados",
                "Fecha"
            ];
    
            const rows = data.map((data, index) => {
                const date = new Date(data.created_at);
                const formattedDate = date.toLocaleDateString('es-ES', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });
            
                return [
                    name,
                    recurso,
                    index + 1,
                    data.duracion,
                    data.completados ? "completado" : "incompleto",
                    formattedDate
                ];
            });
    
            // Generar la tabla
            doc.autoTable({
                head: [columns],
                body: rows,
                startY: startY, // Posiciona la tabla justo debajo de la imagen
            });
    
            // Guardar el PDF con un nombre
            doc.save(`reporte_alumno_${id}.pdf`);
        };
    }

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);


    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getAssignTeacherByIdAssign(idAlumno);
        if (response.assignments && response.assignments.length > 0) {
            setAlumnos(response.assignments);
            setRecurso(`${response.assignments[0].assign.course.title} Nivel: ${response.assignments[0].assign.course.nivel}`);
            setGrado(`${response.assignments[0].degreeAssign} grado ' ${response.assignments[0].sectionAssign} '`);
        } else {
            setRecurso("Ninguno");
            setGrado("Ninguno");
        }
    };

    // GUARDAR
    const saveOrUpdateTeacher = async () => {
        const response = await InsertOrUpdateAssignTeacherNota(fields);
        console.log(response);
        handleClose();
        handleClose3();
        loadData();
    };

    return (
        <>
            <h5>{recurso}</h5>
            {/* <h6>{grado}</h6> */}

            <div className="row mt-3">
                <div className="col-12 ">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Nombres</th>
                                    <th>Apellidos</th>
                                    <th>Grado</th>
                                    <th>Sección</th>
                                    <th>Intentos</th>
                                    <th>Apreciación</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {alumnos && alumnos.length > 0 ? (
                                    alumnos.map((alumno) => (
                                        <tr key={alumno.id}>
                                            <td>{alumno.student.name}</td>
                                            <td>{alumno.student.lastName}</td>
                                            <td>{alumno.degreeAssign}</td>
                                            <td>{alumno.sectionAssign}</td>
                                            <td>{alumno.intentos}</td>
                                            <td>{alumno.note}</td>
                                            <td className='text-center d-flex gap-2'>
                                                <button className="btn btn-primary" onClick={() => { handleShow(alumno.id, alumno.student.name) }}>
                                                    <i className="fa-solid fa-edit"></i>
                                                </button>
                                                <button className="btn btn-secondary" onClick={() => { handleShow2(alumno.id, alumno.student.name) }}>
                                                    <i className="fa-solid fa-eye"></i>
                                                </button>

                                                <button className="btn btn-success" onClick={() => { handleShow3(alumno.id, alumno.student.name) }}>
                                                    <i className="fa-solid fa-message"></i>
                                                </button>

                                                <button className="btn btn-danger" onClick={() => { generarReporte(alumno.id, `${alumno.student.name} ${alumno.student.lastName}`) }}>
                                                    <i className="fa-solid fa-file"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            No hay datos disponibles.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <MyModal
                show={show}
                handleClose={handleClose}
                title={name}
                content={<FrmNota fields={fields} setFields={setFields} id={id} />}
                save={saveOrUpdateTeacher}
                color={true}
            />

            <MyModal
                show={show2}
                handleClose={handleClose2}
                title={name}
                content={<TableProgreso id={id2} />}
                save={saveOrUpdateTeacher}
                ocultarBtn={true}
                color={true}
            />

            <MyModal
                show={show3}
                handleClose={handleClose3}
                title={name}
                content={<FrmComentario fields={fields} setFields={setFields} id={id3} />}
                save={saveOrUpdateTeacher}
                color={true}
            />

        </>
    )
}

export default FrmCurso