import React from "react";
import screen_img from "../../assets/img/screen.png";
import { Link } from "react-router-dom";

const CardGame = ({ titulo, nivel, id, idAssign }) => {

  return (
    <div className="main__item__card">
      <div className="main__item__Card__info">
        <div className="main__item__card__container__info">
          <span className="main__item__card__titulo__">{titulo}</span>
          <span className="main__item__card__subtitulo__">Nivel: {nivel}</span>
        </div>
        <div className="d-flex gap-2">
          <Link to="/alumno/juegos" state={{ title: titulo, nivel: nivel, id: id }}>
            <button className="main__btn main__btn__primary">Jugar</button>
          </Link>
          <Link to="/alumno/archivos" state={{ idAssign: idAssign, title: titulo, }}>
            <button className="main__btn main__btn__outline__primary">Archivos</button>
          </Link>
        </div>

      </div>
      <img src={screen_img} alt="" />
    </div>
  );
};

export default CardGame;
