import React, { useState, useEffect, useRef } from 'react';
import './css/Puzzle.css';

// CONFETI
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import { InsertProgresoEstudiante, updateProgreso } from '../../../service/progresoEstudiante';

const Puzzle = ({ nivel, id }) => {
    const imageList = ["../puzzle_1.jpg", "../puzzle_2.jpg", "../puzzle_3.jpg"];
    const [imageUrl, setImageUrl] = useState("");
    const gridSize = nivel == 1 ? 3 : nivel == 2 ? 4 : 6;
    const [pieces, setPieces] = useState([]);
    const [showPreview, setShowPreview] = useState(false);

    // CONSTANTES
    const [timeTaken, setTimeTaken] = useState(null);
    const [progreso, setProgreso] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [insertar, setInsertar] = useState(true);
    const hasInsertedProgress = useRef(false);


    // CONFETI
    const [showConfetti, setShowConfetti] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (insertar && !hasInsertedProgress.current) {
            hasInsertedProgress.current = true;
            (async () => {
                const defaultFields = {
                    idAssignTeacher: id,
                    duracion: '0',
                    completados: 0,
                };
                const response = await InsertProgresoEstudiante(defaultFields);
                setProgreso(response.progreso_estudiante);
                console.log(response.progreso_estudiante);
            })();
        }
    }, [insertar, startTime, id]);

    useEffect(() => {
        const randomImage = imageList[Math.floor(Math.random() * imageList.length)];
        setImageUrl(randomImage);

        const tempPieces = [];
        for (let i = 0; i < gridSize * gridSize; i++) {
            tempPieces.push(i);
        }
        setPieces(shuffleArray(tempPieces));
    }, [gridSize]);

    const shuffleArray = (array) => {
        let shuffled = array.slice();
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('pieceIndex', index);
    };

    const handleDrop = (e, index) => {
        // e.preventDefault();
        const draggedPieceIndex = e.dataTransfer.getData('pieceIndex');
        swapPieces(draggedPieceIndex, index);
    };

    const handleTouchStart = (e, index) => {
        const touch = e.touches[0];
        const element = e.currentTarget;

        // Crear y mostrar el elemento de previsualización
        let preview = document.querySelector(`[data-preview]`);
        if (!preview) {
            preview = document.createElement('div');
            preview.setAttribute('data-preview', 'true');
            preview.style.position = 'fixed';
            preview.style.pointerEvents = 'none'; // Para que no interfiera con el arrastre
            preview.style.zIndex = '1000'; // Asegurar que esté encima de otros elementos
            document.body.appendChild(preview);
        }

        const computedStyles = window.getComputedStyle(element);
        const backgroundSize = computedStyles.backgroundSize;

        preview.style.backgroundImage = element.style.backgroundImage;
        preview.style.backgroundPosition = element.style.backgroundPosition;
        preview.style.backgroundSize = backgroundSize;
        preview.style.width = `${element.offsetWidth}px`;
        preview.style.height = `${element.offsetHeight}px`;
        preview.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
        preview.style.backgroundClip = 'content-box';
        preview.style.border = '1px solid #000';

        // Configurar el arrastre
        element.setAttribute('data-dragging', index);
    };


    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const preview = document.querySelector(`[data-preview]`);

        if (preview) {
            // Mover la previsualización para seguir el toque
            preview.style.left = `${touch.clientX - (preview.offsetWidth / 2)}px`;
            preview.style.top = `${touch.clientY - (preview.offsetHeight / 2)}px`;
        }
    };

    const handleTouchEnd = (e) => {
        const element = document.querySelector(`[data-dragging]`);
        const preview = document.querySelector(`[data-preview]`);

        if (preview) {
            document.body.removeChild(preview);
        }

        if (element) {
            const touch = e.changedTouches[0];
            const dropIndex = getDropIndex(touch.clientX, touch.clientY);
            if (dropIndex !== null) {
                swapPieces(element.getAttribute('data-dragging'), dropIndex);
            }
            element.style.position = '';
            element.removeAttribute('data-dragging');
            element.removeAttribute('data-touch-start');
        }
    };

    const getDropIndex = (x, y) => {
        const puzzleContainer = document.querySelector('.puzzle-container');
        const containerRect = puzzleContainer.getBoundingClientRect();

        const cellSize = containerRect.width / gridSize;

        const column = Math.floor((x - containerRect.left) / cellSize);
        const row = Math.floor((y - containerRect.top) / cellSize);

        const index = row * gridSize + column;

        if (index >= 0 && index < pieces.length) {
            return index;
        }

        return null;
    };

    const swapPieces = (fromIndex, toIndex) => {
        const newPieces = [...pieces];
        [newPieces[toIndex], newPieces[fromIndex]] = [newPieces[fromIndex], newPieces[toIndex]];
        setPieces(newPieces);

        if (!startTime) setStartTime(Date.now());

        if (newPieces.every((piece, idx) => piece === idx)) {
            setShowConfetti(true);
            setShowMessage(true);

            const audio = new Audio('/sounds/applause-small-audience-97257.mp3');
            audio.play();

            const endTime = Date.now();
            setTimeTaken((endTime - startTime) / 1000);

            const defaultFields = {
                id: progreso.id,
                duracion: formatTime((endTime - startTime) / 1000),
                completados: 1,
            };

            updateProgreso(defaultFields).then(response => {
                console.log(response);
            });

            setTimeout(() => {
                setShowConfetti(false);
                setShowMessage(false);

                // window.location = "/alumno";
                navigate('/alumno');
            }, 6000);
        }
    };

    
    function formatTime(tiempo) {
        const hours = Math.floor(tiempo / 3600);
        const minutes = Math.floor((tiempo % 3600) / 60);
        const seconds = tiempo % 60;

        const pad = (num) => (num < 10 ? `0${num}` : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };

    return (

        <div className="puzzle-wrapper">
            <button onClick={() => setShowPreview(!showPreview)}>
                {showPreview ? "Ocultar vista previa" : "Ver vista previa"}
            </button>



            <div
                className="puzzle-container"
                style={{ '--grid-size': gridSize }}
            >
                {showPreview && (
                    <div className="image-preview">
                        <img src={imageUrl} alt="Vista previa" />
                    </div>
                )}

                {pieces.map((piece, index) => (
                    <div
                        key={index}
                        className="puzzle-piece"
                        style={{
                            backgroundImage: `url(${imageUrl})`,
                            backgroundPosition: `${(piece % gridSize) * (100 / (gridSize - 1))}% ${Math.floor(piece / gridSize) * (100 / (gridSize - 1))}%`,
                        }}
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e, index)}
                        onTouchStart={(e) => handleTouchStart(e, index)}
                        onTouchMove={(e) => handleTouchMove(e)}
                        onTouchEnd={(e) => handleTouchEnd(e)}
                    />
                ))}
            </div>
            {showConfetti && <Confetti />}
            {showMessage && (
                <div style={messageStyle}>
                    ¡Ganaste!
                </div>
            )}

        </div>

    );
};


const messageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    fontSize: '2rem',
    zIndex: 1000,
    textAlign: 'center',
};

export default Puzzle;
