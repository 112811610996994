import { baseURLServicio } from '../config';
import * as METHOD from '../methods';
const URL = `${baseURLServicio}`;


export const login = async (body) => {
    try {
        const response = await fetch(`${URL}/login`, METHOD.POST(body));
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const register = async (body) => {
    try {
        const response = await fetch(`${URL}/register`, METHOD.POST(body));
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

// PROFESOR
export const loginProfesor = async (body) => {
    try {
        const response = await fetch(`${URL}/loginProfesor`, METHOD.POST(body));
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};



// ALUMNO
export const loginAlumno = async (body) => {
    try {
        const response = await fetch(`${URL}/loginAlumno`, METHOD.POST(body));
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};