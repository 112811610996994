import React from "react";
import { Button, Modal } from "react-bootstrap";

const MyModal = ({ show, handleClose, title, content, save = () => {}, size="md", ocultarBtn = false, color = false }) => {
    const modalStyle = color ? { backgroundColor: "#00000060" } : {};

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size={size}
                style= {modalStyle}
                >
                <Modal.Header closeButton >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body> {content} </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={save} hidden={ocultarBtn}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MyModal;
