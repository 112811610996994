import React, { useEffect, useState } from "react";

// APIS
import {
    getStudents,
    InsertOrUpdateStudent,
    getStudentById,
    deleteStudentApi
} from "../../service/students";

// MODAL
import MyModal from "../../components/modal";

// FORMULARIO
import FrmStudent from "./frmStudent";

// ALERTAS
import {
    AlertWarning,
    AlertSuccess,
    AlertError,
    AlertDelete,
} from "../../components/alerts";

const ShowStudents = () => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        idStudent: 0,
        nameStudent: "",
        lastNameStudent: "",
        addressStudent: "",
        phoneStudent: "",
        nameRepresentante: "",
        phoneRepresentante: "",
        emailStudent: "",
        passwordStudent: "",
    };

    // CONSTANTES
    const [show, setShow] = useState(false);
    const [fields, setFields] = useState(defaultFields);
    const [students, setStudents] = useState([]);

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getStudents();
        if (response.students) {
            setStudents(response.students);
        }
    };

    // VALIDACION DE CAMPOS
    const validateFields = () => {
        const excludedFields = ["idStudent"];

        const nameFields = {
            nameStudent: "Nombres",
            lastNameStudent: "Apellidos",
            addressStudent: "Dirección",
            phoneStudent: "Celular",
            nameRepresentante: "Nombre del representante",
            phoneRepresentante: "Celular del representante",
            emailStudent: "Correo",
            passwordStudent: "Contraseña",
        };

        for (const fieldName in fields) {
            if (!excludedFields.includes(fieldName) && !fields[fieldName]) {
                AlertWarning(`El campo ${nameFields[fieldName]} es obligatorio`);
                return false;
            }
        }
        return true;
    };
    
    // MOSTRAR | OCULTAR MODAL
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFields(defaultFields);
    };

    // GUARDAR
    const saveOrUpdateStudent = async () => {
        if (!validateFields()) {
            return;
        }

        const data = {
            id: fields.idStudent,
            name: fields.nameStudent,
            lastName: fields.lastNameStudent,
            address: fields.addressStudent,
            phone: fields.phoneStudent,
            nameRepresentante: fields.nameRepresentante,
            phoneRepresentante: fields.phoneRepresentante,
            email: fields.emailStudent,
            password: fields.passwordStudent,
        };

        const response = await InsertOrUpdateStudent(data);

        if (response.student) {
            await loadData();
            await AlertSuccess(`Registro de forma satisfactoría`);
        } else {
            return await AlertError(response.message);
        }

        setFields(defaultFields);
        handleClose();
    };

    // EDITAR
    const editClient = async (id) => {
        handleShow();

        const response = await getStudentById(id);
        const student = response.student;

        const datos = {
            idStudent: student.id,
            nameStudent: student.name,
            lastNameStudent: student.lastName,
            addressStudent: student.address,
            phoneStudent: student.phone,
            nameRepresentante: student.nameRepresentante,
            phoneRepresentante: student.phoneRepresentante,
            emailStudent: student.email,
            passwordStudent: student.password,
        };

        setFields(datos);
        console.log(fields);
    };

    // ELIMINAR
    const deleteStudent = async (id) => {
        const resultado = await AlertDelete();
        if (resultado) {
            await deleteStudentApi(id);
            loadData();
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="mt-3">
                    <h1>Alumnos</h1>
                </div>
                <div className="row mt-3 ">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success px-5" onClick={handleShow}>
                            <i className="fa-solid fa-circle-plus"></i> Añadir
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 ">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombres</th>
                                        <th>Apellidos</th>
                                        <th>Dirección de Domicilio</th>
                                        <th>Celular</th>
                                        <th>Correo</th>
                                        <th>Nombre Representante</th>
                                        <th>Celular Representante</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {students && students.length > 0 ? (
                                        students.map((student) => (
                                            <tr key={student.id}>
                                                <td>{student.id}</td>
                                                <td>{student.name}</td>
                                                <td>{student.lastName}</td>
                                                <td>{student.address}</td>
                                                <td>{student.phone}</td>
                                                <td>{student.email}</td>
                                                <td>{student.nameRepresentante}</td>
                                                <td>{student.phoneRepresentante}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => editClient(student.id)}
                                                    >
                                                        <i className="fa-solid fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => deleteStudent(student.id)}
                                                    >
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                No hay datos disponibles.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <MyModal
                show={show}
                handleClose={handleClose}
                title={"Nuevo Alumno"}
                content={<FrmStudent fields={fields} setFields={setFields} />}
                save={saveOrUpdateStudent}
                size="lg"
            />
        </>
    );
};

export default ShowStudents;
