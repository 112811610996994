import React, { useEffect, useState } from "react";

// APIS
import { InsertOrUpdateAssignTeacher, deleteAssignTeacherApi, getAssignTeacher, getAssignTeacherById } from "../../service/assignTeacher";

// MODAL
import MyModal from "../../components/modal";

// FORMULARIO
import FrmAssignTeacher from "./frmAssignTeacher";

// ALERTAS
import {
    AlertWarning,
    AlertSuccess,
    AlertError,
    AlertDelete,
} from "../../components/alerts";


const ShowAssignTeacher = () => {
    // OBTENER AÑO LECTIVO
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const startYear = currentMonth <= 8 ? currentYear : currentYear - 1;
    const endYear = startYear + 1;

    // CAMPOS POR DEFECTO
    const defaultFields = {
        id: 0,
        idAssign: 0,
        idStudent: 0,
        degreeAssign: "",
        sectionAssign: "",
        anioLectivo: startYear + "-" + endYear,
        intentos: 0,
        note: "No asignado." 
    };

    // CONSTANTES
    const [show, setShow] = useState(false);
    const [fields, setFields] = useState(defaultFields);
    const [assigns, setAssigns] = useState([]);

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getAssignTeacher();
        if (response.assignmentsTeacher) {
            setAssigns(response.assignmentsTeacher);
        }
        console.log(response);
    };

    // VALIDACION DE CAMPOS
    const validateFields = () => {
        const excludedFields = ["id", "note", "intentos", "anioLectivo", "duracion", "completados"];

        const nameFields = {
            idAssign: "Profesor",
            idStudent: "Estudiante",
            degreeAssign: "Grado",
            sectionAssign: "Sección"
        };

        for (const fieldName in fields) {
            if (!excludedFields.includes(fieldName) && !fields[fieldName]) {
                AlertWarning(`El campo ${nameFields[fieldName]} es obligatorio`);
                return false;
            }
        }
        return true;
    };
    
    // MOSTRAR | OCULTAR MODAL
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFields(defaultFields);
    };

    // GUARDAR
    const saveOrUpdateAssignTeacher = async () => {
        if (!validateFields()) {
            return;
        }

        const response = await InsertOrUpdateAssignTeacher(fields);

        if (response.assignmentTeacher) {
            await loadData();
            await AlertSuccess(`Registro de forma satisfactoría`);
        } else {
            return await AlertError(response.message);
        }

        setFields(defaultFields);
        handleClose();
    };

    // EDITAR
    const editAssignTeacher = async (id) => {
        handleShow();

        const response = await getAssignTeacherById(id);

        setFields(response.assignmentTeacher);
        console.log(fields);
    };

    // ELIMINAR
    const deleteAssignTeacher = async (id) => {
        const resultado = await AlertDelete();
        if (resultado) {
            await deleteAssignTeacherApi(id);
            loadData();
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="mt-3">
                    <h1>Asignar Profesor a Alumno</h1>
                </div>
                <div className="row mt-3 ">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success px-5" onClick={handleShow}>
                            <i className="fa-solid fa-circle-plus"></i> Añadir
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 ">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Recurso</th>
                                        <th>Profesor</th>
                                        <th>Estudiante</th>
                                        <th>Grado</th>
                                        <th>Sección</th>
                                        <th>Año Lectivo</th>
                                        <th>Intentos</th>
                                        <th>Apreciación</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {assigns.length > 0 ? (
                                        assigns.map((assign) => (
                                            <tr key={assign.id}>
                                                <td>{assign.id}</td>
                                                <td>{assign.assign.course.title} Nivel: {assign.assign.course.nivel}</td>
                                                <td>{assign.assign.teacher.name}</td>
                                                <td>{assign.student.name}</td>
                                                <td>{assign.degreeAssign}</td>
                                                <td>{assign.sectionAssign}</td>
                                                <td>{assign.anioLectivo}</td>
                                                <td>{assign.intentos}</td>
                                                <td>{assign.note}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => editAssignTeacher(assign.id)}
                                                    >
                                                        <i className="fa-solid fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => deleteAssignTeacher(assign.id)}
                                                    >
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                No hay datos disponibles.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <MyModal
                show={show}
                handleClose={handleClose}
                title={"Nuevo Asignamiento"}
                content={<FrmAssignTeacher fields={fields} setFields={setFields} />}
                save={saveOrUpdateAssignTeacher}
            />
        </>
    );
};

export default ShowAssignTeacher;
