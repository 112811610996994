import React, { useState, useEffect } from "react";
// OBTENER PROFESORES
import { getAssign } from "../../service/assign";
// OBTENER CURSOS
import { getStudents } from "../../service/students";

const FrmAssignTeacher = ({ fields, setFields }) => {
    // CONSTANTES
    const [assign, setAssigns] = useState([]);
    const [students, setStudents] = useState([]);

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        // ===== PROFESORES
        const response1 = await getAssign();
        if (response1.assignments) {
            setAssigns(response1.assignments);
        }

        // ===== CURSOS
        const response2 = await getStudents();
        if (response2.students) {
            setStudents(response2.students);
        }
    };

    // AGREGA VALOR A FIELDS
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Profesor
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="idAssign"
                    value={fields.idAssign}
                    onChange={handleInputChange}
                >
                    <option value="0" disabled>
                        Elegir un profesor...
                    </option>
                    {assign.length > 0 ? (
                        assign.map((assign) => (
                            <option key={assign.id} value={assign.id}>
                                {assign.teacher.name} {assign.teacher.lastName}-{assign.course.title} Nivel: {assign.course.nivel}
                            </option>
                        ))
                    ) : (
                        <option value="1">Sin datos</option>
                    )}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Alumno
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="idStudent"
                    value={fields.idStudent}
                    onChange={handleInputChange}
                >
                    <option value="0" disabled>
                        Elegir un alumno...
                    </option>
                    {students.length > 0 ? (
                        students.map((student) => (
                            <option key={student.id} value={student.id}>
                                {student.name} {student.lastName}
                            </option>
                        ))
                    ) : (
                        <option value="1">Sin datos</option>
                    )}
                </select>
            </div>

            <div className="row">
                <div className="col-6">
                    <div className="mb-3">
                        <label htmlFor="degreeAssign" className="form-label">
                        Grado
                        </label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            name="degreeAssign"
                            value={fields.degreeAssign}
                            onChange={handleInputChange}
                            
                        >
                            <option value="" disabled>
                                Elegir un Grado...
                            </option>
                            <option value="Primero">
                                Primero
                            </option>
                            <option value="Segundo">
                                Segundo
                            </option>
                            <option value="Tercero">
                                Tercero
                            </option>
                            <option value="Cuarto">
                                Cuarto
                            </option>
                            <option value="Quinto">
                                Quinto
                            </option>
                            <option value="Sexto">
                                Sexto
                            </option>
                            <option value="Séptimo">
                                Séptimo
                            </option>

                        </select>
                    </div>
                </div>

                <div className="col-6">
                    <div className="mb-3">
                        <label htmlFor="sectionAssign" className="form-label">
                            Sección
                        </label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            name="sectionAssign"
                            value={fields.sectionAssign}
                            onChange={handleInputChange}
                            
                        >
                            <option value="" disabled>
                                Elegir una sección...
                            </option>
                            <option value="A">
                                A
                            </option>
                            <option value="B">
                                B
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="degreeAssign" className="form-label">
                    Año Lectivo
                </label>
                <input
                    type="text"
                    className="form-control"
                    name="anioLectivo"
                    disabled
                    value={fields.anioLectivo}
                    onChange={handleInputChange}
                />
            </div>


            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Apreciación
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="note"
                    value={fields.note}
                    onChange={handleInputChange}
                    disabled
                >
                    <option value="No asignado." selected>
                        No asignado.
                    </option>
                    <option value="Domina los Aprendizajes.">
                        Domina los Aprendizajes.
                    </option>
                    <option value="Alcanza los Aprendizajes.">
                        Alcanza los Aprendizajes.
                    </option>
                    <option value="Está próximo a alcanzar los Aprendizajes.">
                        Está próximo a alcanzar los Aprendizajes.
                    </option>
                    <option value="No alcanza los Aprendizajes.">
                        No alcanza los Aprendizajes.
                    </option>

                </select>
            </div>

        </>
    );
};

export default FrmAssignTeacher;
