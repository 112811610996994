import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// CSS
import "../../assets/css/navbarProfesor.css";

import logo from "../../assets/img/logo_3.png";

const Disenio = ({ content }) => {
    // CONSTANTES
    const [nameTeacher, setNameTeacher] = useState();
    const location = useLocation();

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = () => {
        const name = localStorage.getItem("profesorName");
        setNameTeacher(name);
    };

    // SALIR
    const handleLogout = () => {
        window.location = "/profesor/login";
        localStorage.removeItem("tokenProfesor");
        localStorage.removeItem("profesorName");
        localStorage.removeItem("profesorID");
    };

    return (
        <>
            <div className="principal">
                <div className="sidebar">
                    <nav>
                        <div>
                            <div className="profile">
                            <img src={logo} alt="" className="logo"/>
                                <h3>{nameTeacher}</h3>
                            </div>
                        </div>
                        <hr />
                        <ul>
                            <Link to={"/profesor/recursos"} aria-current="page">
                                <li
                                    className={`${location.pathname === "/profesor/recursos" ? "active" : ""
                                        }`}
                                >
                                    <i className="fa-solid fa-home"></i>

                                    Recursos Elaborados

                                </li>
                            </Link>

                            <Link to={"/profesor/elegirRecursos"} aria-current="page">
                                <li
                                    className={`${location.pathname === "/profesor/elegirRecursos" ? "active" : ""
                                        }`}
                                >
                                    <i className="fa-solid fa-gamepad"></i>

                                    Elegir Recursos

                                </li>
                            </Link>

                            <Link to={"/profesor/elegirAlumnos"} aria-current="page">
                                <li
                                    className={`${location.pathname === "/profesor/elegirAlumnos" ? "active" : ""
                                        }`}
                                >
                                    <i className="fa-solid fa-users"></i>

                                    Elegir Alumnos 2

                                </li>
                            </Link>

                        </ul>
                    </nav>

                    <div className="salir">
                        <hr />

                        <ul>
                            {/* <Link to={"/profesor/login"}> */}
                                <li onClick={handleLogout}>
                                    <i className="fa-solid fa-right-from-bracket"></i>
                                    <a href="#" >Salir</a>
                                </li>
                            {/* </Link> */}
                        </ul>
                    </div>
                </div>
                <div className="content">{content}</div>
         
            </div>
        </>
    );
};

export default Disenio;
