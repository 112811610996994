import React, { useState, useEffect } from 'react'
// APIS
import { getCourses } from '../../../service/courses';
import { InsertOrUpdateAssign, getAssignByIdTeacher } from '../../../service/assign';

// ALERTAS
import {
   AlertSuccess,
   AlertError
} from "../../../components/alerts";

const ElegirRecursos = () => {
   // CONSTANTES
   const [recursos, setRecursos] = useState();
   const [selectedRecursos, setSelectedRecursos] = useState([]);
   const idTeacher = localStorage.getItem("profesorID");
   const [groupedCourses, setGroupedCourses] = useState([]);

   // INICA LOS DATOS
   useEffect(() => {
      loadData();
   }, []);

   // OBTIENE LOS DATOS
   const loadData = async () => {
      const response = await getCourses();
      const recursosTengo = await getAssignByIdTeacher(idTeacher);
      if (response.courses) {
         setRecursos(response.courses);

         const groupByCategory = response.courses.reduce((acc, course) => {
            if (!acc[course.category]) {
               acc[course.category] = [];
            }
            acc[course.category].push(course);
            return acc;
         }, {});

         setGroupedCourses(groupByCategory);
         console.log(groupedCourses);
         console.log(recursos);
      }

      if (recursosTengo.assignments) {
         const assignedCourses = recursosTengo.assignments.map(assign => assign.course.id);
         // console.log(assignedCourses);
         setSelectedRecursos(assignedCourses);
      }
   };

   // GUARDAR RECURSOS MARCADOS
   const handleCheckboxChange = (id) => {
      setSelectedRecursos(prevState =>
         prevState.includes(id)
            ? prevState.filter(recursoId => recursoId !== id)
            : [...prevState, id]
      );
   };

   // AGREGAR NUEVO RECURSO A PROFESOR
   const handleSave = async () => {
      const selected = recursos.filter(recurso => selectedRecursos.includes(recurso.id));

      selected.map(async (recurso) => {
         const datos = {
            idTeacher: idTeacher,
            idCourse: recurso.id
         };
         const response = await InsertOrUpdateAssign(datos);
         if (response.assignment) {
            await loadData();
         } else {
            // return await AlertError(response.message);
         }

         console.log(response);

      })

      await AlertSuccess(`Registro de forma satisfactoría`);
      console.log('Selected Resources:', selected);
   };

   const [activeCategory, setActiveCategory] = useState(null);

   const toggleCategory = (category) => {
      setActiveCategory(activeCategory === category ? null : category);
   };

   return (
      <>
         <h1 className='mb-4'>Elegir Recursos</h1>

         {/* <div className="list-group mb-3">

            { Object.keys(groupedCourses) &&  Object.keys(groupedCourses).length > 0 ? (
                Object.keys(groupedCourses).map((category, idx) => (
                  <div key={idx}>
                     <label className='h4 mt-3'>{category}</label>
                     
                     {groupedCourses[category].map((recurso) => (
                        <label key={recurso.id} className="list-group-item">
                           <input
                                 className="form-check-input me-1"
                                 type="checkbox"
                                 checked={selectedRecursos.includes(recurso.id)}
                                 onChange={() => handleCheckboxChange(recurso.id)}
                                 // disabled= {selectedRecursos.includes(recurso.id)}
                           />
                           {recurso.title} - Nivel: {recurso.nivel}
                        </label>
                     ))}
                  </div>
               ))
            ) : (
               <li className="list-group-item d-flex align-items-center" >
                  <label className="form-check-label" htmlFor="resource3">
                     No hay recursos disponibles.
                  </label>
               </li>
            )}
         </div> */}

         <div className="list-group mb-3">
            {Object.keys(groupedCourses) && Object.keys(groupedCourses).length > 0 ? (
               Object.keys(groupedCourses).map((category, idx) => (
                  <div className="card mb-2" key={idx}>
                     <div className="card-header" id={`heading-${idx}`}>
                        <h4 className="mb-0">
                           <button
                              className="btn btn-block text-start"
                              type="button"
                              onClick={() => toggleCategory(category)}
                              aria-expanded={activeCategory === category}
                              aria-controls={`collapse-${idx}`}
                           >
                              {category}
                           </button>
                        </h4>
                     </div>
                     <div
                        id={`collapse-${idx}`}
                        className={`collapse ${activeCategory === category ? 'show' : ''}`}
                        aria-labelledby={`heading-${idx}`}
                        data-parent=".list-group"
                     >
                        <div className="card-body">
                           {groupedCourses[category].map((recurso) => (
                              <label key={recurso.id} className="list-group-item">
                                 <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    checked={selectedRecursos.includes(recurso.id)}
                                    onChange={() => handleCheckboxChange(recurso.id)}
                                 // disabled= {selectedRecursos.includes(recurso.id)}
                                 />
                                 {recurso.title} - Nivel: {recurso.nivel}
                              </label>
                           ))}
                        </div>
                     </div>
                  </div>
               ))
            ) : (
               <li className="list-group-item d-flex align-items-center">
                  <label className="form-check-label">
                     No hay recursos disponibles.
                  </label>
               </li>
            )}
         </div>

         <button className='btn btn-success' onClick={handleSave}>Agregar</button>


      </>
   )
}

export default ElegirRecursos