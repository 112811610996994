import { baseURLServicio } from '../config';
import * as METHOD from '../methods';
const URL = `${baseURLServicio}`;

export const getAssignTeacher = async () => {
    try {
        const response = await fetch(`${URL}/assignTeacher`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignTeacherById = async (id) => {
    try {
        const response = await fetch(`${URL}/assignTeacher/${id}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignTeacherByIdAssign = async (id) => {
    try {
        const response = await fetch(`${URL}/assignTeacherAssign/${id}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};


export const getAssignCouseByIdAssign = async (id) => {
    try {
        const response = await fetch(`${URL}/assignCourseAssign/${id}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignCouseByIdAssignCategoria = async (id, category) => {
    try {
        const encodedCategory = encodeURIComponent(category);

        const response = await fetch(`${URL}/assignCourseAssignCategoria/${id}/${encodedCategory}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const InsertOrUpdateAssignTeacher = async (body) => {
    try {
        const method = body.id && body.id !== 0 ? 'PUT' : 'POST';

        let response = ""
        if (method === 'PUT') {
            response = await fetch(`${URL}/assignTeacher/${body.id}`, METHOD.PUT(body));
        }else{
            response = await fetch(`${URL}/assignTeacher`, METHOD.POST(body));
        }

        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const InsertOrUpdateAssignTeacherNota = async (body) => {
    try {
        const response = await fetch(`${URL}/assignTeacherNota/${body.id}`, METHOD.PUT(body));
        
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const assignTeacherIntentos = async (body) => {
    try {
        const response = await fetch(`${URL}/assignTeacherIntentos/${body.id}`, METHOD.PUT(body));

        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const assignTeacherDuracion = async (body) => {
    try {
        const response = await fetch(`${URL}/assignTeacherDuracion/${body.id}`, METHOD.PUT(body));

        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteAssignTeacherApi = async (id) => {
    try {
        const response = await fetch(`${URL}/assignTeacher/${id}`, METHOD.DELETE());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};