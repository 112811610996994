import React, { useState, useEffect } from "react";
import { getArchivos, getAssignByIdTeacher, uploadArchivosAssign } from "../../../service/assign";

import { useNavigate } from 'react-router-dom';

const FrmArchivo = ({ archivos, setArchivos, id, setShow2 }) => {

    // CONSTANTES
    const [fields, setFields] = useState([]);
    const [title, setTitle] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const navigate = useNavigate();

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleTitle = (event) => {
        const { name, value } = event.target;
        setTitle(value);
    }

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getArchivos(id);
        if (response.archivos) {
            setFields(response.archivos);
        }
        console.log(response.archivos);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert("No se selecciono ningun archivo");
            return;
        }

        if (!title) {
            alert("Titulo no puede ser vacio");
            return;
        }

        console.log(selectedFile);

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('related_id', id);
        formData.append('title', title);

        const file = formData.get('file');
        console.log(file);


        const response = await uploadArchivosAssign(formData)
        console.log(response);

        // window.location = "/profesor/recursos";
        setShow2(false);
        // navigate('/profesor');

    }

    return (
        <div>
            <div className="row mt-3">
                <div className="col-12 ">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Titulo</th>
                                    <th>Tipo</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {fields && fields.length > 0 ? (
                                    fields.map((archivo) => {
                                        // Obtener la extensión del archivo
                                        const extension = archivo.file_path.split('.').pop();

                                        return (
                                            <tr key={archivo.id}>
                                                <td>{archivo.title}</td>    
                                                {/* <td>{archivo.file_path}</td> */}
                                                <td>{extension}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="3">No hay archivos disponibles</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <input className="form-control mb-3" type="text" onChange={handleTitle} placeholder="Ingresar titulo" />
            <input className="form-control mb-3" type="file" onChange={handleFileChange} />
            <button className="btn btn-success" onClick={handleUpload}>Subir Archivo</button>
        </div>
    )
}

export default FrmArchivo