import React, { useState, useEffect, useRef } from "react";
import "./css/MatchingGame.css";
import { assignTeacherDuracion } from "../../../service/assignTeacher";

// IMAGENES
import img1 from "./img/image_1.png";
import img2 from "./img/image_2.png";
import img3 from "./img/image_3.png";
import img4 from "./img/image_4.png";
import img5 from "./img/image_5.png";
import img6 from "./img/image_6.png";
import img7 from "./img/image_7.png";
import img8 from "./img/image_8.png";
import img9 from "./img/image_9.png";
import img10 from "./img/image_10.png";
import img11 from "./img/image_11.png";
import img12 from "./img/image_12.png";
import {
    InsertProgresoEstudiante,
    updateProgreso,
} from "../../../service/progresoEstudiante";

// CONFETI
import Confetti from "react-confetti";

import { useNavigate } from 'react-router-dom';

const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
];

// Crea una función para generar cartas en función del nivel
function generateCards(level) {
    const numberOfPairs = level * 4; // Aumenta el número de pares con el nivel
    let cards = [];
    for (let i = 0; i < numberOfPairs; i++) {
        const image = images[i % images.length]; // Usa las imágenes importadas
        cards.push({ id: i * 2, image: image });
        cards.push({ id: i * 2 + 1, image: image });
    }
    return cards;
}

function MatchingGame({ nivel, id }) {
    const [shuffledCards, setShuffledCards] = useState([]);
    const [insertar, setInsertar] = useState(true);
    const [progreso, setProgreso] = useState([]);
    const [flippedCards, setFlippedCards] = useState([]);
    const [matchedCards, setMatchedCards] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [timeTaken, setTimeTaken] = useState(null);

    const hasInsertedProgress = useRef(false);

    // CONFETI
    const [showConfetti, setShowConfetti] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setShuffledCards(shuffle(generateCards(nivel)));
    }, [nivel]);

    useEffect(() => {
        if (flippedCards.length === 2) {
            const [firstCard, secondCard] = flippedCards;
            if (firstCard.image === secondCard.image) {
                setMatchedCards([...matchedCards, firstCard.id, secondCard.id]);
            }
            setTimeout(() => setFlippedCards([]), 1000);
        }
    }, [flippedCards]);

    useEffect(() => {
        if (insertar && !hasInsertedProgress.current) {
            hasInsertedProgress.current = true;
            (async () => {
                const defaultFields = {
                    idAssignTeacher: id,
                    duracion: "0",
                    completados: 0,
                };
                const response = await InsertProgresoEstudiante(defaultFields);
                setProgreso(response.progreso_estudiante);
                console.log(response.progreso_estudiante);
            })();
        }
    }, [insertar, startTime, id]);

    useEffect(() => {
        const updateDuration = async () => {
            if (matchedCards.length === shuffledCards.length && startTime) {
                setTimeTaken(Date.now() - startTime);
                setShowConfetti(true);
                setShowMessage(true);

                const audio = new Audio('/sounds/applause-small-audience-97257.mp3'); 
                audio.play();

                const defaultFields = {
                    id: progreso.id,
                    duracion: formatTime((Date.now() - startTime) / 1000),
                    completados: 1,
                };

                updateProgreso(defaultFields).then((response) => {
                    console.log(response);
                });

                setTimeout(() => {
                    setShowConfetti(false);
                    setShowMessage(false);
                    // window.location = "/alumno";
                    navigate('/alumno');
                }, 6000);
            }
        };

        updateDuration();
    }, [matchedCards, startTime, shuffledCards, id]);

    function shuffle(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    function handleCardClick(cardGame) {
        const audio = new Audio('/sounds/flipcard-91468.mp3'); 
        audio.play();

        if (!startTime) setStartTime(Date.now());
        if (
            flippedCards.length < 2 &&
            !flippedCards.includes(cardGame) &&
            !matchedCards.includes(cardGame.id)
        ) {
            setFlippedCards([...flippedCards, cardGame]);
        }
    }

    function formatTime(tiempo) {
        const hours = Math.floor(tiempo / 3600);
        const minutes = Math.floor((tiempo % 3600) / 60);
        const seconds = tiempo % 60;

        const pad = (num) => (num < 10 ? `0${num}` : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }

    return (
        <>
            <div className="matching-game">
                <h2>Memoria de imagenes - Nivel {nivel}</h2>
                <div className={`${nivel == 3 ? "cards-grid-3" : "cards-grid"}`}>
                    {shuffledCards.map((cardGame) => (
                        <div
                            key={cardGame.id}
                            className={`cardGame ${flippedCards.includes(cardGame) ||
                                    matchedCards.includes(cardGame.id)
                                    ? ""
                                    : "flipped"
                                }`}
                            onClick={() => handleCardClick(cardGame)}
                        >
                            <div className="cardGame-front">
                                <img src={cardGame.image} alt={`Card ${cardGame.id}`} />
                            </div>
                            <div className="cardGame-back"></div>
                        </div>
                    ))}
                </div>
                {showConfetti && <Confetti />}
            {showMessage && <div style={messageStyle}>¡Ganaste!</div>}
            </div>
            
        </>
    );
}

const messageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0, 0, 0, 0.7)",
    color: "white",
    padding: "20px",
    borderRadius: "10px",
    fontSize: "2rem",
    zIndex: 1000,
    textAlign: "center",
};

export default MatchingGame;
