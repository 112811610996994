import React, { useState } from "react";
// APIS
import { loginAlumno } from "../../../service/login";

// ALERTAS
import {
    AlertError
} from "../../../components/alerts";
import { Link } from "react-router-dom";

import { useNavigate } from 'react-router-dom';

const LoginAlumno = () => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        id: 0,
        name: "",
        email: "",
        password: "",
    };

    // CONSTANTES
    const [fields, setFields] = useState(defaultFields);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    // VALIDAR DATOS
    const validar = async () => {
        const response = await loginAlumno(fields);
        if (response.student) {
            console.log(response);
            localStorage.setItem('tokenAlumno', response.token);
            localStorage.setItem('alumnoName', response.student.name);
            localStorage.setItem('alumnoGrado', response.student.degree);
            localStorage.setItem('alumnoSection', response.student.section);
            localStorage.setItem('alumnoID', response.student.id);

            window.location = "/alumno";
            
            // navigate('/alumno');
   
        } else {
            return await AlertError(response.message);
        }
    }

    return (
        <>
            <div className="login-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 login-sec">
                            <h2 className="text-center">Alumno</h2>
                            <form className="login-form">
                                <div className="form-group mb-3 px-4">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="text-uppercase"
                                    >
                                        Correo
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="email"
                                        value={fields.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group mb-3 px-4">
                                    <label
                                        htmlFor="exampleInputPassword1"
                                        className="text-uppercase"
                                    >
                                        Contraseña
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder=""
                                        name="password"
                                        value={fields.password}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-check">
                                    <button type="button" className="btn btn-login float-end me-4" onClick={validar}>
                                        Iniciar Sesion
                                    </button>
                                </div>
                            </form>
                            <div className="copy-text"><Link to={"/profesor/login"} >Soy Profesor</Link> <i className="fa fa-heart"></i> <Link to={"/"} >Soy Administrador</Link></div>
                        </div>
                        <div className="col-md-8 banner-sec">
                            <div
                                id="carouselExampleIndicators"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img
                                            className="d-block w-100"
                                            src="https://elcomercio.pe/resizer/D0q_2FsbC4fOr8W1yvcMtRC13ns=/980x528/smart/filters:format(jpeg):quality(75)/cloudfront-us-east-1.images.arcpublishing.com/elcomercio/Z3ZYPUXHLVEPTJSQRYNKDSSGEU.jpeg"
                                            alt="First slide"
                                            style={{ height: "590px", objectFit: "cover"}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginAlumno;
