import React, { useState, useEffect } from 'react'
// APIS
import { getAssign, getAssignByIdTeacher } from '../../../service/assign';
import { getStudents } from '../../../service/students';
import { InsertOrUpdateAssignTeacher } from '../../../service/assignTeacher';


// ALERTAS
import {
    AlertSuccess,
    AlertError
} from "../../../components/alerts";

const ElegirAlumnos = () => {
    // OBTENER AÑO LECTIVO
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const startYear = currentMonth <= 8 ? currentYear : currentYear - 1;
    const endYear = startYear + 1;

    // CAMPOS POR DEFECTO
    const defaultFields = {
        id: 0,
        idAssign: 0,
        idStudent: 0,
        degreeAssign: 0,
        sectionAssign: 0,
        anioLectivo: startYear + "-" + endYear,
        note: "No asignado."
    };

    // CONSTANTES
    const [fields, setFields] = useState(defaultFields);
    const [alumnos, setAlumnos] = useState();
    const [selectedAlumnos, setSelectedAlumnos] = useState([]);
    const [assign, setAssigns] = useState([]);
    const idTeacher = localStorage.getItem("profesorID");

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getStudents();
        if (response.students) {
            setAlumnos(response.students);
        }
        // // ALUMNOS QUE YA TENGO
        // const alumnosTengo = await getAssignByIdTeacher(idTeacher);
        // if (alumnosTengo.assignments) {
        //     const assignedCourses = alumnosTengo.assignments.map(assign => assign.course.id);
        //     setSelectedAlumnos(assignedCourses);
        // }

        // ===== PROFESORES
        // const response1 = await getAssign();
        // if (response1.assignments) {
        //     setAssigns(response1.assignments);
        // }

        const response1 = await getAssignByIdTeacher(localStorage.getItem("profesorID"));
        if (response1.assignments) {
            setAssigns(response1.assignments);
        }
        console.log(response1.assignments);
    };

    // GUARDAR RECURSOS MARCADOS
    const handleCheckboxChange = (id) => {
        setSelectedAlumnos(prevState =>
            prevState.includes(id)
                ? prevState.filter(alumnoId => alumnoId !== id)
                : [...prevState, id]
        );
    };

    // AGREGAR NUEVO RECURSO A PROFESOR
    const handleSave = async () => {
        const selected = alumnos.filter(alumno => selectedAlumnos.includes(alumno.id));

        var contador = 0

        // await selected.map(async (alumno) => {
        //     const datos = {
        //         id: 0,
        //         idAssign: fields.idAssign,
        //         idStudent: alumno.id,
        //         degreeAssign: fields.degreeAssign,
        //         sectionAssign: fields.sectionAssign,
        //         anioLectivo:  fields.anioLectivo,
        //         note: fields.note
        //     };
        //     const response = await InsertOrUpdateAssignTeacher(datos);
        //     if (response.assignmentTeacher) {
        //         await loadData();
        //     } else {
        //         contador ++;
        //         alert(contador)
        //         return await AlertError(response.message);
        //     }

        //     console.log(response);

        // })

        for (const alumno of selected) {
            const datos = {
                id: 0,
                idAssign: fields.idAssign,
                idStudent: alumno.id,
                degreeAssign: fields.degreeAssign,
                sectionAssign: fields.sectionAssign,
                anioLectivo: fields.anioLectivo,
                note: fields.note
            };
            
            try {
                const response = await InsertOrUpdateAssignTeacher(datos);
                if (response.assignmentTeacher) {
                    await loadData();
                    contador -=1;
                } else {
                    contador++;
                    await AlertError(response.message);
                    // return; 
                }
            } catch (error) {
                contador++;
                await AlertError(error.message);
                return;
            }
        }

        if (contador <= 0 && selected.length > 0) {
            await AlertSuccess(`Registro de forma satisfactoría`);
        }else if (selected.length <= 0){
            await AlertError(`No hay Alumnos seleccionados`);
        }
        console.log(contador);
        console.log('Selected Resources:', selected);
    };

    // AGREGA VALOR A FIELDS
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <h1 className='mb-4'>Elegir Alumnos</h1>

            <div className="row">
                <div className='col-10'>
                    <div className='row'>
                        <div className='col-4'>
                            <div className="mb-3">
                                <label htmlFor="titleCourse" className="form-label">
                                    Recurso
                                </label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="idAssign"
                                    value={fields.idAssign}
                                    onChange={handleInputChange}
                                >
                                    <option value="0" disabled>
                                        Elegir un recurso...
                                    </option>
                                    {assign.length > 0 ? (
                                        assign.map((assign) => (
                                            <option key={assign.id} value={assign.id}>
                                                {assign.course.title} Nivel: {assign.course.nivel}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="1">Sin datos</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="mb-3">
                                <label htmlFor="degreeAssign" className="form-label">
                                    Grado
                                </label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="degreeAssign"
                                    value={fields.degreeAssign}
                                    onChange={handleInputChange}

                                >
                                    <option value="0" disabled>
                                        Elegir un Grado...
                                    </option>
                                    <option value="Primero">
                                        Primero
                                    </option>
                                    <option value="Segundo">
                                        Segundo
                                    </option>
                                    <option value="Tercero">
                                        Tercero
                                    </option>
                                    <option value="Cuarto">
                                        Cuarto
                                    </option>
                                    <option value="Quinto">
                                        Quinto
                                    </option>
                                    <option value="Sexto">
                                        Sexto
                                    </option>
                                    <option value="Séptimo">
                                        Séptimo
                                    </option>

                                </select>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="mb-3">
                                <label htmlFor="sectionAssign" className="form-label">
                                    Sección
                                </label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="sectionAssign"
                                    value={fields.sectionAssign}
                                    onChange={handleInputChange}

                                >
                                    <option value="0" disabled>
                                        Elegir una sección...
                                    </option>
                                    <option value="A">
                                        A
                                    </option>
                                    <option value="B">
                                        B
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div className='col-2'>
                            <div className="mb-3">
                                <label htmlFor="degreeAssign" className="form-label">
                                    Año Lectivo
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="anioLectivo"
                                    disabled
                                    value={fields.anioLectivo}
                                    onChange={handleInputChange}
                                />
                            </div>

                        </div>

                        <div className='col-2'>
                            <div className="mb-3">
                                <label htmlFor="titleCourse" className="form-label">
                                    Apreciación
                                </label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="note"
                                    value={fields.note}
                                    onChange={handleInputChange}
                                    disabled
                                >
                                    <option value="No asignado." selected>
                                        No asignado.
                                    </option>
                                    <option value="Domina los Aprendizajes.">
                                        Domina los Aprendizajes.
                                    </option>
                                    <option value="Alcanza los Aprendizajes.">
                                        Alcanza los Aprendizajes.
                                    </option>
                                    <option value="Está próximo a alcanzar los Aprendizajes.">
                                        Está próximo a alcanzar los Aprendizajes.
                                    </option>
                                    <option value="No alcanza los Aprendizajes.">
                                        No alcanza los Aprendizajes.
                                    </option>

                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="list-group mb-3">
                {alumnos && alumnos.length > 0 ? (
                    alumnos.map((alumno) => (
                        <label key={alumno.id} className="list-group-item">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                checked={selectedAlumnos.includes(alumno.id)}
                                onChange={() => handleCheckboxChange(alumno.id)}
                            />
                            {alumno.name}
                        </label>
                    ))
                ) : (
                    <li className="list-group-item d-flex align-items-center" >
                        <label className="form-check-label" htmlFor="resource3">
                            No hay alumnos disponibles.
                        </label>
                    </li>
                )}
            </div>

            <button className='btn btn-success' onClick={handleSave}>Agregar</button>


        </>
    )
}

export default ElegirAlumnos