import React, { useState, useEffect } from "react";
// OBTENER PROFESORES
import { getTeachers } from "../../service/teachers";
// OBTENER CURSOS
import { getCourses } from "../../service/courses";

const FrmAssignCourse = ({ fields, setFields }) => {
    const defaultRecurso = {
        category: "-1",
        title: "-1",
        nivel: "1"
    };

    // CONSTANTES
    const [fieldsR, setFieldsR] = useState(defaultRecurso);
    const [teachers, setTeachers] = useState([]);
    const [courses, setCourses] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [titulos, setTitulos] = useState([]);

    // INICIA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        // ===== PROFESORES
        const response1 = await getTeachers();
        if (response1.teachers) {
            setTeachers(response1.teachers);
        }

        // ===== CURSOS
        const response2 = await getCourses();
        if (response2.courses) {
            setCourses(response2.courses);
            const uniqueCategories = [...new Set(response2.courses.map(course => course.category))];
            setCategorias(uniqueCategories);
        }
    };

    // AGREGA VALOR A FIELDS
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    // OBTENER TITULOS
    const obtenerTitulos = (category) => {
        const filtered = courses
            .filter(course => course.category === category)
            .map(course => course.title);

        const uniqueTitles = [...new Set(filtered)];
        setTitulos(uniqueTitles);
    };

    const handleRecursoChange = (event) => {
        const { name, value } = event.target;
        setFieldsR((prev) => {
            const newFieldsR = { ...prev, [name]: value };
            if (name === "category") {
                obtenerTitulos(value);
                newFieldsR.title = "-1";
            }
            updateIdCourse(newFieldsR);
            return newFieldsR;
        });
    };

    const updateIdCourse = (fieldsR) => {
        const selectedCourse = courses.find(course =>
            course.category === fieldsR.category &&
            course.title === fieldsR.title &&
            course.nivel === fieldsR.nivel
        );

        if (selectedCourse) {
            setFields(prev => ({ ...prev, idCourse: selectedCourse.id }));
        }
    };

    return (
        <>
            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Profesor
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="idTeacher"
                    value={fields.idTeacher}
                    onChange={handleInputChange}
                >
                    <option value="0" disabled>
                        Elegir un profesor...
                    </option>
                    {teachers.length > 0 ? (
                        teachers.map((teacher) => (
                            <option key={teacher.id} value={teacher.id}>
                                {teacher.name} {teacher.lastName}
                            </option>
                        ))
                    ) : (
                        <option value="1">Sin datos</option>
                    )}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Categorías
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="category"
                    value={fieldsR.category}
                    onChange={handleRecursoChange}
                >
                    <option value="-1" disabled>
                        Elegir una categoría...
                    </option>
                    {categorias.length > 0 ? (
                        categorias.map((category, index) => (
                            <option key={index} value={category}>
                                {category}
                            </option>
                        ))
                    ) : (
                        <option value="">Sin datos</option>
                    )}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Recurso
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="title"
                    value={fieldsR.title}
                    onChange={handleRecursoChange}
                >
                    <option value="-1" disabled>
                        Elegir un recurso...
                    </option>
                    {titulos.length > 0 ? (
                        titulos.map((titulo, index) => (
                            <option key={index} value={titulo}>
                                {titulo}
                            </option>
                        ))
                    ) : (
                        <option value="">Sin datos</option>
                    )}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Nivel
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    name="nivel"
                    value={fieldsR.nivel}
                    onChange={handleRecursoChange}
                >
                    <option value="-1" disabled>
                        Elegir un nivel...
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            </div>
        </>
    );
};

export default FrmAssignCourse;
