import { baseURLServicio } from '../config';
import * as METHOD from '../methods';
const URL = `${baseURLServicio}`;

export const getProgresoEstudiante = async () => {
    try {
        const response = await fetch(`${URL}/progreso`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getProgresoEstudianteById = async (id) => {
    try {
        const response = await fetch(`${URL}/progreso/${id}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};



export const InsertProgresoEstudiante = async (body) => {
    try {
        const response = await fetch(`${URL}/progreso`, METHOD.POST(body));

        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const updateProgreso = async (body) => {
    try {
        const response = await fetch(`${URL}/progreso/${body.id}`, METHOD.PUT(body));

        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

