import React, { useState, useEffect } from 'react'

import { Link, useLocation } from "react-router-dom";
import { getArchivos } from '../../../service/assign';

const ArchivosAlumno = () => {
    // CONSTANTES

    const location = useLocation();
    const { idAssign, title } = location.state;
    const [archivos, setArchivos] = useState();

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);


    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getArchivos(idAssign);
        if (response.archivos) {
            setArchivos(response.archivos);
        }
    };

    return (
        <>
            <div className='main__container__'>
                <div className='container__perfil'>

                    <div className='container__alumno'>
                        <span className='main__titulo__'>{title}</span>

                        {archivos && archivos.length > 0 ? (
                            archivos.map((archivo) => (
                                <div className='main__container__datos' key={archivo.id}>
                                    <span className='main__subtitulo__'>{archivo.title}</span>
                                    <a href={"https://focuskids.xyz/api/storage/uploads/" + archivo.file_path} className='main__datos__' download={archivo.title} target="_blank" rel="noopener noreferrer">
                                        Descargar archivo
                                    </a>
                                    {/* <a href={"https://focuskids.xyz/api/storage/" + archivo.file_path} className='main__datos__' download={archivo.title} target="_blank" rel="noopener noreferrer">
                                        Descargar archivo
                                    </a> */}
                                </div>
                            ))
                        ) : (
                            <p>No hay archivos disponibles.</p>
                        )}


                        <div className='btn__regresar btn_primero'>
                            <Link to="/alumno">
                                <button className="main__btn main__btn__outline__primary">Regresar</button>
                            </Link>

                        </div>


                    </div>

                    <div className='btn__regresar btn_segundo'>
                        <Link to="/alumno">
                            <button className="main__btn main__btn__outline__primary">Regresar</button>
                        </Link>

                    </div>

                </div>



            </div>
        </>
    )
}

export default ArchivosAlumno