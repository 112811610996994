import { baseURLServicio } from '../config';
import * as METHOD from '../methods';
const URL = `${baseURLServicio}`;

export const getAssign = async () => {
    try {
        const response = await fetch(`${URL}/assign`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignById = async (id) => {
    try {
        const response = await fetch(`${URL}/assign/${id}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignByIdTeacher = async (id) => {
    try {
        const response = await fetch(`${URL}/assign/teacher/${id}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignByParametros = async (idTeacher, idCourse, sectionAssign, degreeAssign) => {
    try {
        const response = await fetch(`${URL}/assign/${idTeacher}/${idCourse}/${sectionAssign}/${degreeAssign}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const InsertOrUpdateAssign = async (body) => {
    try {
        const method = body.id && body.id !== 0 ? 'PUT' : 'POST';

        let response = ""
        if (method === 'PUT') {
            response = await fetch(`${URL}/assign/${body.id}`, METHOD.PUT(body));
        }else{
            response = await fetch(`${URL}/assign`, METHOD.POST(body));
        }

        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteAssignApi = async (id) => {
    try {
        const response = await fetch(`${URL}/assign/${id}`, METHOD.DELETE());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const uploadArchivosAssign = async (body) => {
    try {
        const response = await fetch(`${URL}/assign/upload`, {
          method: 'POST',
          body: body, // FormData que contiene el archivo
          // No incluir 'Content-Type' ya que el navegador lo gestiona cuando se usa FormData
        });
    
        if (!response.ok) {
          throw new Error(`Error HTTP! status: ${response.status}`);
        }
    
        const data = await response.json();
        return data;
      } catch (err) {
        console.log('Error en la subida de archivos:', err);
        throw err;
      }
};


export const getArchivos = async (id) => {
    try {
        const response = await fetch(`${URL}/assign/getArchivos/${id}`, METHOD.GET());
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};