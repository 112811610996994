import React, { useState, useEffect } from "react";
// APIS
import { getAssignCouseByIdAssign } from "../../../service/assignTeacher";
import { Link } from "react-router-dom";

const CursosAlumno = () => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        id: 0,
        idAssign: 0,
        idStudent: 0,
        note: ""
    };

    // CONSTANTES
    const [cursos, setCursos] = useState();

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);


    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getAssignCouseByIdAssign(localStorage.getItem("alumnoID"));
        if (response.assignments) {
            setCursos(response.assignments);
        }
        console.log(response.assignments);
    };


    return (
        <>
            <h1 className="mb-4">Recursos</h1>

            {cursos && cursos.length > 0 ? (
                cursos.map((curso) => (
                    <div className="card mb-3" key={curso.id}>
                        <div className="card-header">
                            Profesor@: {curso.assign.teacher.name} {curso.assign.degreeAssign} grado "{curso.assign.sectionAssign}"
                        </div>

                        <div className="card-body">
                            <h5 className="card-title">{curso.assign.course.title} {curso.assign.course.nivel}</h5>
                            <p className="card-text">Lorem ipsum is the card on the note all. Every one more section lorem</p>
                            <h4 className="text-success"> {curso.note} </h4>

                            <Link to="/alumno/juegos"
                                state={{ title: curso.assign.course.title, nivel: curso.assign.course.nivel }
                                }>Jugar</Link>

                        </div>
                    </div>
                ))
            ) : (
                <h5 className="text-center">
                    No hay datos disponibles.
                </h5>
            )}


        </>
    );
};

export default CursosAlumno;
