import React, { useState, useRef, useEffect } from 'react';

import { InsertProgresoEstudiante, updateProgreso } from '../../../service/progresoEstudiante';

// CSS
import "./css/NumberOperation.css";

// CONFETI
import Confetti from 'react-confetti';

import { useNavigate } from 'react-router-dom';

const NumberOperation = ({ nivel, id }) => {
    // Ajustar las rondas totales según el nivel
    const totalRounds = nivel == 1 ? 5 : nivel == 2 ? 10 : 15;

    // CONSTANTES
    const [timeTaken, setTimeTaken] = useState(null);
    const [progreso, setProgreso] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [insertar, setInsertar] = useState(true);
    const hasInsertedProgress = useRef(false);

    // CONFETI
    const [showConfetti, setShowConfetti] = useState(false);

    const navigate = useNavigate();

    const generateOperation = () => {
        const num1 = Math.floor(Math.random() * 20) + 1;
        const num2 = Math.floor(Math.random() * 20) + 1;
        const operation = Math.random() > 0.5 ? 'sum' : 'sub';

        const correctAnswer = operation === 'sum' ? num1 + num2 : num1 - num2;
        const question = operation === 'sum' ? `${num1} + ${num2} = ?` : `${num1} - ${num2} = ?`;

        return { question, correctAnswer };
    };

    const generateOptions = (correctAnswer) => {
        const wrongAnswer1 = correctAnswer + Math.floor(Math.random() * 5) + 1;
        const wrongAnswer2 = correctAnswer - Math.floor(Math.random() * 5) - 1;

        const options = [correctAnswer, wrongAnswer1, wrongAnswer2].sort(() => Math.random() - 0.5);

        return options;
    };

    useEffect(() => {
        if (insertar && !hasInsertedProgress.current) {
            hasInsertedProgress.current = true;
            (async () => {
                const defaultFields = {
                    idAssignTeacher: id,
                    duracion: '0',
                    completados: 0,
                };
                const response = await InsertProgresoEstudiante(defaultFields);
                setProgreso(response.progreso_estudiante);
                console.log(response.progreso_estudiante);
            })();
        }
    }, [insertar, startTime, id]);

    const [operation, setOperation] = useState(generateOperation());
    const [options, setOptions] = useState(generateOptions(operation.correctAnswer));
    const [feedback, setFeedback] = useState('');
    const [round, setRound] = useState(1);
    const [gameOver, setGameOver] = useState(false);

    const handleOptionClick = (option) => {
        
        if (!startTime) setStartTime(Date.now());

        if (option === operation.correctAnswer) {
            const audio = new Audio('/sounds/correct-6033.mp3'); 
            audio.play();
            setFeedback('¡Correcto!');
            
        } else {
            const audio = new Audio('/sounds/error-126627.mp3'); 
            audio.play();
            setFeedback('Inténtalo de nuevo.');
        }

        if (round < totalRounds) {
            setTimeout(() => {
                const newOperation = generateOperation();
                setOperation(newOperation);
                setOptions(generateOptions(newOperation.correctAnswer));
                setFeedback('');
                if (option === operation.correctAnswer) {
                    setRound(prevRound => prevRound + 1);
                }
            }, 1500);
        } else {
            setTimeout(() => {
                setGameOver(true);
                setFeedback('¡Juego Terminado!');

                setShowConfetti(true);
                const audio = new Audio('/sounds/applause-small-audience-97257.mp3'); 
                audio.play();

                const endTime = Date.now();
                setTimeTaken((endTime - startTime) / 1000);

                const defaultFields = {
                    id: progreso.id,
                    duracion: formatTime((endTime - startTime) / 1000),
                    completados: 1,
                };
    
                updateProgreso(defaultFields).then(response => {
                    console.log(response);
                });

                setTimeout(() => {
                    setShowConfetti(false);
                    // window.location = "/alumno";
                    navigate('/alumno');
                }, 6000);

            }, 1500);
        }
    };

    // Calcular el progreso por estrella según el nivel
    const roundsPerStar = Math.ceil(totalRounds / 5);
    const filledStars = Math.floor(round / roundsPerStar);
    const partialStarProgress = (round % roundsPerStar) / roundsPerStar;

    function formatTime(tiempo) {
        const hours = Math.floor(tiempo / 3600);
        const minutes = Math.floor((tiempo % 3600) / 60);
        const seconds = tiempo % 60;

        const pad = (num) => (num < 10 ? `0${num}` : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };

    return (
        <div className='pizarra'>
            {/* Mostrar estrellas */}
            <div className='estrellas'>
                {[...Array(5)].map((_, i) => (
                    <span key={i}>
                        {i < filledStars ? (
                            <i className="fas fa-star" style={{ color: '#FFD700', fontSize: '24px' }}></i>
                        ) : i === filledStars && partialStarProgress > 0 ? (
                            <i className="fas fa-star-half-alt" style={{ color: '#FFD700', fontSize: '24px' }}></i>
                        ) : (
                            <i className="far fa-star" style={{ color: '#FFD700', fontSize: '24px' }}></i>
                        )}
                    </span>
                ))}
            </div>
            {!gameOver ? (
                <>
                    <h1>{operation.question}</h1>
                    <div className='container_button'>
                        {options.map((option, index) => (
                            <button key={index} onClick={() => handleOptionClick(option)}>
                                {option}
                            </button>
                        ))}
                    </div>
                    <p className='respuesta'>{feedback}</p>

                </>
            ) : (
                <div>
                    <h2 className='titulo'>¡Has completado el juego!</h2>
                </div>
            )}

            {showConfetti && <Confetti />}
            
        </div>
    );
};



export default NumberOperation;
