import React, { useEffect, useState } from "react";

// APIS
import {
    getCourses,
    InsertOrUpdateCourse,
    getCourseById,
    deleteCourseApi
} from "../../service/courses";

// MODAL
import MyModal from "../../components/modal";

// FORMULARIO
import FrmCourse from "./frmCourse";

// ALERTAS
import {
    AlertWarning,
    AlertSuccess,
    AlertError,
    AlertDelete,
} from "../../components/alerts";

const ShowCourses = () => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        idCourse: 0,
        categoryCourse: "Juegos de Concentración",
        titleCourse: "",
        desCourse: "",
        nivelCourse: "1"
    };

    // CONSTANTES
    const [show, setShow] = useState(false);
    const [fields, setFields] = useState(defaultFields);
    const [courses, setCourses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getCourses();
        if (response.courses) {
            setCourses(response.courses);
        }
    };

    // VALIDACION DE CAMPOS
    const validateFields = () => {
        const excludedFields = ["idCourse"];

        const nameFields = {
            categoryCourse: "Categoria",
            titleCourse: "Titulo",
            desCourse: "Descripcion",
            nivelCourse: "nivel"
        };

        for (const fieldName in fields) {
            if (!excludedFields.includes(fieldName) && !fields[fieldName]) {
                AlertWarning(`El campo ${nameFields[fieldName]} es obligatorio`);
                return false;
            }
        }
        return true;
    };

    // MOSTRAR | OCULTAR MODAL
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFields(defaultFields);
    };

    // GUARDAR
    const saveOrUpdateCourse = async () => {
        if (!validateFields()) {
            return;
        }

        const data = {
            id: fields.idCourse,
            category: fields.categoryCourse,
            title: fields.titleCourse,
            des: fields.desCourse,
            nivel: fields.nivelCourse
        };

        const response = await InsertOrUpdateCourse(data);

        if (response.course) {
            await loadData();
            await AlertSuccess(`Registro de forma satisfactoría`);
        } else {
            return await AlertError(response.message);
        }

        setFields(defaultFields);
        handleClose();
    };

    // EDITAR
    const editClient = async (id) => {
        handleShow();

        const response = await getCourseById(id);
        const course = response.course;

        const datos = {
            idCourse: course.id,
            categoryCourse: course.category,
            titleCourse: course.title,
            desCourse: course.des,
            nivelCourse: course.nivel
        };

        setFields(datos);
        console.log(fields);
    };

    // ELIMINAR
    const deleteCourse = async (id) => {
        const resultado = await AlertDelete();
        if (resultado) {
            await deleteCourseApi(id);
            loadData();
        }
    };

    // Filtrar cursos basados en el término de búsqueda
    const filteredCourses = courses.filter(course =>
        course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.nivel.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.des.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <div className="container-fluid">
                <div className="mt-3">
                    <h1>Recursos</h1>
                </div>
                <div className="row mt-3 ">
                    <div className="d-flex justify-content-end">

                        <div className="input-group me-3" style={{ width: "300px" }}>
                            <input
                                type="text"
                                placeholder="Buscar..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-control"

                            />
                            <button
                                type="button"
                                class="btn btn-secondary"
                            >
                                <i className="fa fa-search"></i>
                            </button>

                        </div>
                        <button className="btn btn-success px-5" onClick={handleShow}>
                            <i className="fa-solid fa-circle-plus"></i> Añadir
                        </button>
                    </div>
                </div>



                <div className="row mt-3">
                    <div className="col-12 ">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        {/* <th>#</th> */}
                                        <th>Categoria</th>
                                        <th>Titulo</th>
                                        <th>Nivel</th>
                                        <th>Descripción</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {filteredCourses.length > 0 ? (
                                        filteredCourses.map((course) => (
                                            <tr key={course.id}>
                                                {/* <td>{course.id}</td> */}
                                                <td>{course.category}</td>
                                                <td>{course.title}</td>
                                                <td>{course.nivel}</td>
                                                <td style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                    {course.des}
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => editClient(course.id)}
                                                    >
                                                        <i className="fa-solid fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => deleteCourse(course.id)}
                                                    >
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                No hay datos disponibles.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <MyModal
                show={show}
                handleClose={handleClose}
                title={"Nuevo Recurso"}
                content={<FrmCourse fields={fields} setFields={setFields} />}
                save={saveOrUpdateCourse}
            />
        </>
    );
};

export default ShowCourses;
