import React, { useEffect, useState } from "react";

// APIS
import {
    getTeachers,
    InsertOrUpdateTeacher,
    getTeacherById,
    deleteTeacherApi
} from "../../service/teachers";

// MODAL
import MyModal from "../../components/modal";

// FORMULARIO
import FrmTeacher from "./frmTeacher";

// ALERTAS
import {
    AlertWarning,
    AlertSuccess,
    AlertError,
    AlertDelete,
} from "../../components/alerts";

const ShowTeachers = () => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        idTeacher: 0,
        nameTeacher: "",
        lastNameTeacher: "",
        addressTeacher: "",
        phoneTeacher: "",
        emailTeacher: "",
        passwordTeacher: "",
    };

    // CONSTANTES
    const [show, setShow] = useState(false);
    const [fields, setFields] = useState(defaultFields);
    const [teachers, setTeachers] = useState([]);

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getTeachers();
        if (response.teachers) {
            setTeachers(response.teachers);
        }
    };

    // VALIDACION DE CAMPOS
    const validateFields = () => {
        const excludedFields = ["idTeacher"];

        const nameFields = {
            nameTeacher: "Nombres",
            lastNameTeacher: "Apellidos",
            addressTeacher: "Dirección",
            phoneTeacher: "Celular",
            emailTeacher: "Correo",
            passwordTeacher: "Contraseña",
        };

        for (const fieldName in fields) {
            if (!excludedFields.includes(fieldName) && !fields[fieldName]) {
                AlertWarning(`El campo ${nameFields[fieldName]} es obligatorio`);
                return false;
            }
        }
        return true;
    };
    
    // MOSTRAR | OCULTAR MODAL
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFields(defaultFields);
    };

    // GUARDAR
    const saveOrUpdateTeacher = async () => {
        if (!validateFields()) {
            return;
        }

        const data = {
            id: fields.idTeacher,
            name: fields.nameTeacher,
            lastName: fields.lastNameTeacher,
            address: fields.addressTeacher,
            phone: fields.phoneTeacher,
            email: fields.emailTeacher,
            password: fields.passwordTeacher,
        };

        const response = await InsertOrUpdateTeacher(data);

        if (response.teacher) {
            await loadData();
            await AlertSuccess(`Registro de forma satisfactoría`);
        } else {
            return await AlertError(response.message);
        }

        setFields(defaultFields);
        handleClose();
    };

    // EDITAR
    const editClient = async (id) => {
        handleShow();

        const response = await getTeacherById(id);
        const teacher = response.teacher;

        const datos = {
            idTeacher: teacher.id,
            nameTeacher: teacher.name,
            lastNameTeacher: teacher.lastName,
            addressTeacher: teacher.address,
            phoneTeacher: teacher.phone,
            emailTeacher: teacher.email,
            passwordTeacher: teacher.password,
        };

        setFields(datos);
        console.log(fields);
    };

    // ELIMINAR
    const deleteTeacher = async (id) => {
        const resultado = await AlertDelete();
        if (resultado) {
            await deleteTeacherApi(id);
            loadData();
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="mt-3">
                    <h1>Profesores</h1>
                </div>
                <div className="row mt-3 ">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success px-5" onClick={handleShow}>
                            <i className="fa-solid fa-circle-plus"></i> Añadir
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 ">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombres</th>
                                        <th>Apellidos</th>
                                        <th>Dirección de Domicilio</th>
                                        <th>Celular</th>
                                        <th>Correo</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {teachers && teachers.length > 0 ? (
                                        teachers.map((teacher) => (
                                            <tr key={teacher.id}>
                                                <td>{teacher.id}</td>
                                                <td>{teacher.name}</td>
                                                <td>{teacher.lastName}</td>
                                                <td>{teacher.address}</td>
                                                <td>{teacher.phone}</td>
                                                <td>{teacher.email}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => editClient(teacher.id)}
                                                    >
                                                        <i className="fa-solid fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => deleteTeacher(teacher.id)}
                                                    >
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                No hay datos disponibles.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <MyModal
                show={show}
                handleClose={handleClose}
                title={"Nuevo Profesor"}
                content={<FrmTeacher fields={fields} setFields={setFields} />}
                save={saveOrUpdateTeacher}
            />
        </>
    );
};

export default ShowTeachers;
