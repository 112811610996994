import React, { useEffect } from 'react'
// APIS
import { getAssignTeacherById } from '../../../service/assignTeacher';

const FrmComentario = ({ fields, setFields, id }) => {
    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getAssignTeacherById(id);
        if (response.assignmentTeacher) {
            setFields(response.assignmentTeacher);
        }
    };

    // AGREGA VALOR A FIELDS
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <div className="mb-3">
                <label htmlFor="titleCourse" className="form-label">
                    Comentario
                </label>
                <textarea className='form-control' name='comentario' onChange={handleInputChange} value={fields.comentario} placeholder="Comentario..."></textarea>
            </div>
        </>
    )
}

export default FrmComentario