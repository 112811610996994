import { Router } from './router/router';
import './assets/css/login.css'

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
