import React, { useEffect, useState } from "react";

// APIS
import { InsertOrUpdateAssign, deleteAssignApi, getAssign, getAssignById } from "../../service/assign";

// MODAL
import MyModal from "../../components/modal";

// FORMULARIO
import FrmAssignCourse from "./frmAssignCourse";

// ALERTAS
import {
    AlertWarning,
    AlertSuccess,
    AlertError,
    AlertDelete,
} from "../../components/alerts";


const ShowAssignCourses = () => {
    // CAMPOS POR DEFECTO
    const defaultFields = {
        id: 0,
        idTeacher: 0,
        idCourse: 0
        // degreeAssign: 0,
        // sectionAssign: 0
    };

    // CONSTANTES
    const [show, setShow] = useState(false);
    const [fields, setFields] = useState(defaultFields);
    const [assigns, setAssigns] = useState([]);

    // INICA LOS DATOS
    useEffect(() => {
        loadData();
    }, []);

    // OBTIENE LOS DATOS
    const loadData = async () => {
        const response = await getAssign();
        if (response.assignments) {
            setAssigns(response.assignments);
        }
        console.log(response);
    };

    // VALIDACION DE CAMPOS
    const validateFields = () => {
        const excludedFields = ["id"];

        const nameFields = {
            idTeacher: "Profesor",
            idCourse: "Recurso",
            // degreeAssign: "Grado",
            // sectionAssign: "Sección"
        };

        for (const fieldName in fields) {
            if (!excludedFields.includes(fieldName)) {
                const fieldValue = fields[fieldName];
    
                // Verificar si el campo está vacío
                if (!fieldValue) {
                    AlertWarning(`El campo ${nameFields[fieldName]} es obligatorio`);
                    return false;
                }
    
                // Verificar si sectionAssign está en mayúsculas
                if (fieldName === 'sectionAssign' && !/^[A-Z]+$/.test(fieldValue)) {
                    AlertWarning(`El campo ${nameFields[fieldName]} debe estar en mayúsculas`);
                    return false;
                }
            }
        }
        return true;
    };
    
    // MOSTRAR | OCULTAR MODAL
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFields(defaultFields);
    };

    // GUARDAR
    const saveOrUpdateAssign = async () => {
        if (!validateFields()) {
            return;
        }


        const response = await InsertOrUpdateAssign(fields);

        if (response.assignment) {
            await loadData();
            await AlertSuccess(`Registro de forma satisfactoría`);
        } else {
            return await AlertError(response.message);
        }

        setFields(defaultFields);
        handleClose();
    };

    // EDITAR
    const editAssign = async (id) => {
        handleShow();

        const response = await getAssignById(id);

        setFields(response.assignment);
        console.log(fields);
    };

    // ELIMINAR
    const deleteAssign = async (id) => {
        const resultado = await AlertDelete();
        if (resultado) {
            await deleteAssignApi(id);
            loadData();
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="mt-3">
                    <h1>Asignar Recurso a Profesor</h1>
                </div>
                <div className="row mt-3 ">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success px-5" onClick={handleShow}>
                            <i className="fa-solid fa-circle-plus"></i> Añadir
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 ">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Profesor</th>
                                        <th>Recurso</th>
                                        {/* <th>Grado</th>
                                        <th>Sección</th> */}
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {assigns.length > 0 ? (
                                        assigns.map((assign) => (
                                            <tr key={assign.id}>
                                                <td>{assign.id}</td>
                                                <td>{assign.teacher.name} {assign.teacher.lastName}</td>
                                                <td>{assign.course.title} - Nivel: {assign.course.nivel}</td>
                                                {/* <td>{assign.degreeAssign}</td>
                                                <td>{assign.sectionAssign}</td> */}
                                                <td>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => editAssign(assign.id)}
                                                    >
                                                        <i className="fa-solid fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => deleteAssign(assign.id)}
                                                    >
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                No hay datos disponibles.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <MyModal
                show={show}
                handleClose={handleClose}
                title={"Nuevo Asignamiento"}
                content={<FrmAssignCourse fields={fields} setFields={setFields} />}
                save={saveOrUpdateAssign}
            />
        </>
    );
};

export default ShowAssignCourses;
